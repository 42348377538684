import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import CarlaText from '../../../../../../components/CarlaText';
import DialogButton from '../../../../../../components/DialogButton';
import CustomTextField from '../../../../../../components/CustomTextField';
import Select from '../../../../../../components/Select';
import { useCallback, useState } from 'react';
import * as uiActions from '../../../../../../state/ui/actions';
import { useDispatch } from 'react-redux';
import { useOutagePlans } from '../../../../../../context/outage-plans';
import LoadingContainer from '../../../../../../components/LoadingContainer';

type Props = {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const NewOutagePlanDashboard = ({ opened, setOpened }: Props) => {
  const [outageCode, setOutageCode] = useState('');
  const [status, setStatus] = useState('');
  const dispatch = useDispatch();
  const { addOutagePlan, loading, error } = useOutagePlans();

  const handleNew = useCallback(async () => {
    if (outageCode && status) {
      try {
        await addOutagePlan(outageCode, status);
        dispatch(uiActions.genericMessage('Added Succesfully'));
        setOpened(false);
      } catch {
        dispatch(uiActions.error('', error ?? 'Add Unsuccesful'));
      }
    } else {
      dispatch(uiActions.error('', 'Please enter valid input'));
    }
    setStatus('');
  }, [outageCode, status]);

  return (
    <Dialog
      open={opened}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Add new outage code</DialogTitle>
      <DialogContent>
        {loading ? (
          <LoadingContainer loading={loading} />
        ) : (
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <CarlaText>Enter outage code and set status</CarlaText>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <CustomTextField
                label="Outage Code"
                inputProps={{ maxLength: 5 }}
                onChange={e => setOutageCode(e.target.value)}
              ></CustomTextField>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Select
                fullWidth
                label="Status"
                options={[
                  { label: 'Planned', value: 'Planned' },
                  { label: 'Complete', value: 'Complete' },
                ]}
                onChange={e => setStatus(e.target.value)}
                value={status}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <DialogButton
          onClick={() => {
            setStatus('');
            setOpened(false);
          }}
        >
          Cancel
        </DialogButton>
        <DialogButton
          color="primary"
          onClick={handleNew}
        >
          Submit
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewOutagePlanDashboard;
