import { useState } from 'react';
import * as R from 'ramda';
import IndicatorRecordTable from '.';
import useIndicator from '../../hooks/indicator';
import Conditional from '../Conditional';
import RecordTableContainer from './RecordTableContainer';
import { IndicatorRecord } from '../../api/indicators/types';
import OldRecordTables from './old/RecordTables';
import isFeatureEnabled from '../../utils/feature-flags';
import { isBlackListedIndicator as checkIfBlackListedIndicator } from '../Scorecard/utils';

interface RecordTableProps {
  records: Array<IndicatorRecord>;
  readonly?: boolean;
  onApply?: any;
  onDismiss?: any;
  onApplicable?: any;
  noPromptApply?: any;
  isLoading: boolean;
}

const RecordTables = ({
  records,
  readonly = false,
  onApply,
  onDismiss,
  onApplicable,
  noPromptApply,
  isLoading,
}: RecordTableProps) => {
  const { id: indicatorId, shortName: indicatorShortName } = useIndicator();
  const groupedRecords = R.groupBy(record => {
    if (R.isNil(record.applicable)) {
      return 'notEvaluated';
    }
    return record.applicable ? 'applicable' : 'notApplicable';
  }, records);
  const notEvaluatedRecords = R.uniq(
    R.propOr<IndicatorRecord[], typeof groupedRecords, IndicatorRecord[]>([], 'notEvaluated', groupedRecords)
  );
  const appliedRecords = R.uniq(
    R.propOr<IndicatorRecord[], typeof groupedRecords, IndicatorRecord[]>([], 'applicable', groupedRecords)
  );
  const notAppliedRecords = R.uniq(
    R.propOr<IndicatorRecord[], typeof groupedRecords, IndicatorRecord[]>([], 'notApplicable', groupedRecords)
  );

  const isBlackListedIndicator = checkIfBlackListedIndicator(indicatorShortName);

  // Expand each list by default
  const [minimized, setMinimized] = useState({
    applied: false,
    notApplied: false,
    notEvaluated: false,
  });

  const toggleSectionVisibility = (sectionName: keyof typeof minimized) => {
    setMinimized(value => ({ ...value, [sectionName]: !value[sectionName] }));
  };

  const toggleMinimizeApplied = () => {
    toggleSectionVisibility('applied');
  };

  const toggleMinimizeNotApplied = () => {
    toggleSectionVisibility('notApplied');
  };

  const toggleMinimizeNotEvaluated = () => {
    toggleSectionVisibility('notEvaluated');
  };

  return (
    <Conditional condition={!isLoading}>
      {isBlackListedIndicator && !R.isEmpty(records) && (
        <RecordTableContainer>
          <IndicatorRecordTable
            indicatorId={indicatorId}
            indicatorShortName={indicatorShortName}
            items={records}
            evaluated={true}
            readonly={true}
          />
        </RecordTableContainer>
      )}
      {!isBlackListedIndicator && !R.isEmpty(notEvaluatedRecords) && (
        <RecordTableContainer
          collapsible
          expanded={!minimized.notEvaluated}
          toggleVisibility={toggleMinimizeNotEvaluated}
          title="Not Evaluated Records"
          count={notEvaluatedRecords.length}
        >
          <IndicatorRecordTable
            indicatorId={indicatorId}
            indicatorShortName={indicatorShortName}
            items={notEvaluatedRecords}
            evaluated={false}
            onApply={onApply}
            onDismiss={onDismiss}
            readonly={readonly}
          />
        </RecordTableContainer>
      )}
      {!isBlackListedIndicator && !R.isEmpty(appliedRecords) && (
        <RecordTableContainer
          collapsible
          expanded={!minimized.applied}
          toggleVisibility={toggleMinimizeApplied}
          title="Applied Records"
          count={appliedRecords.length}
        >
          <IndicatorRecordTable
            indicatorId={indicatorId}
            indicatorShortName={indicatorShortName}
            items={appliedRecords}
            evaluated={false}
            onApply={null}
            onDismiss={onDismiss}
            onApplicable={onApplicable}
            noPromptApply={noPromptApply}
            readonly={readonly}
          />
        </RecordTableContainer>
      )}
      {!isBlackListedIndicator && !R.isEmpty(notAppliedRecords) && (
        <RecordTableContainer
          collapsible
          expanded={!minimized.notApplied}
          toggleVisibility={toggleMinimizeNotApplied}
          title="Not Applied Records"
          count={notAppliedRecords.length}
        >
          <IndicatorRecordTable
            indicatorId={indicatorId}
            indicatorShortName={indicatorShortName}
            items={notAppliedRecords}
            evaluated={false}
            onApply={onApply}
            onDismiss={null}
            onApplicable={onApplicable}
            noPromptApply={noPromptApply}
            readonly={readonly}
          />
        </RecordTableContainer>
      )}
      {R.isEmpty(notEvaluatedRecords) && R.isEmpty(appliedRecords) && R.isEmpty(notAppliedRecords) && (
        <p>
          <em>No records</em>
        </p>
      )}
    </Conditional>
  );
};

export default (props: RecordTableProps) =>
  isFeatureEnabled('liveScorecardOverrides') ? <RecordTables {...props} /> : <OldRecordTables {...props} />;
