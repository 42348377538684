import React from 'react';
import { useRouteMatch } from 'react-router';
import Conditional from '../../../components/Conditional';
import useSingleWalkdown, { SingleWalkdownProvider } from '../../walkdown/hooks/useSingleWalkdown';
import SingleWalkdownInternal from '../../walkdown/SingleWalkdown/SingleWalkdownInternal';
import ApprovalHeader from './ApprovalHeader';

const SingleWalkdown = () => {
  const { isLoading, walkdown, update } = useSingleWalkdown();
  return (
    <Conditional condition={!isLoading}>
      {walkdown && (
        <>
          <ApprovalHeader walkdown={walkdown} />
          <SingleWalkdownInternal
            walkdown={walkdown as any}
            update={update}
            hideBanner
            readOnly
          />
        </>
      )}
    </Conditional>
  );
};

export default () => {
  const match: any = useRouteMatch();

  return (
    <SingleWalkdownProvider walkdownId={match.params.walkdownId}>
      <SingleWalkdown />
    </SingleWalkdownProvider>
  );
};
