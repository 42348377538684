import { ControlRadio } from '../../../../form/ControlRadio';
import { ControlTextField } from '../../../../form/ControlTextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { ControlAutocomplete } from '../../../../form/ControlAutocomplete';
import { Control, FieldErrors } from 'react-hook-form';
import { LabelOptions, RiskApproverFormInputs, RiskEscalationRoles } from '../../../../types/risk-management';
import { getEscalationRoleLabel } from '../hooks/utils';

export type EscalateIssueProps = {
  control: Control<RiskApproverFormInputs>;
  errors: FieldErrors<RiskApproverFormInputs>;
  yesOrNoOptions: { value: string; label: string }[];
  watchEscalateIssue: string | null;
  isReadOnly: boolean;
  riskApprovers: LabelOptions[];
  nextRole: RiskEscalationRoles;
};

export const EscalateIssue = (props: EscalateIssueProps) => {
  const { control, errors, yesOrNoOptions, watchEscalateIssue, isReadOnly, riskApprovers, nextRole } = props;

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <ControlRadio
          control={control}
          error={!!errors.escalateIssue}
          name="escalateIssue"
          label="Want to escalate issue?"
          required
          options={yesOrNoOptions}
          readOnly={isReadOnly}
          row
        />
      </Grid>
      {watchEscalateIssue === 'yes' && (
        <Grid
          item
          xs={12}
        >
          <Box width="30%">
            <ControlAutocomplete
              control={control}
              name="escalateTo"
              options={riskApprovers}
              error={!!errors.escalateUsers}
              label={`Select a ${getEscalationRoleLabel(nextRole)} to escalate to`}
              required={watchEscalateIssue === 'yes'}
              readOnly={isReadOnly}
            />
          </Box>
        </Grid>
      )}
      {!!watchEscalateIssue && (
        <Grid
          item
          xs={12}
        >
          <ControlTextField
            control={control}
            required
            name="escalateIssueRationale"
            error={!!errors.escalateIssueRationale}
            label={watchEscalateIssue === 'yes' ? 'Rationale for Escalating Risk' : 'Rationale for Not Escalating Risk'}
            readOnly={isReadOnly}
            multiline={true}
            minRows={6}
            minLength={1}
            maxLength={3000}
            placeholder="Required (Character Limitation: 3000 characters)"
          />
        </Grid>
      )}
    </>
  );
};
