import { Box, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import styled from 'styled-components';

export type SubmitTextStatus = 'default' | 'submitting' | 'success';

interface StatusTextProps {
  status: SubmitTextStatus;
  submittingText?: string;
  successText?: string;
}

const loadingAnimation = `@keyframes loading {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }`;

const StatusTypography = styled(Typography)<StatusTextProps>`
  color: ${({ status }) => {
    switch (status) {
      case 'submitting':
        return '#FFA22D';
      case 'success':
        return '#4CAF50';
      default:
        return '#4a4a4a';
    }
  }};

  ${({ status }) => {
    if (status === 'submitting') {
      return `
        animation-name: loading;
        animation-duration: 1s;
        animation-iteration-count: infinite;`;
    } else {
      return '';
    }
  }}

  font-weight: 700;
  margin-left: 5px;

  ${loadingAnimation}
`;

const SubmittingIcon = styled(AccessTimeIcon)`
  color: #ffa22d;
  animation-name: loading;
  animation-duration: 1s;
  animation-iteration-count: infinite;

  ${loadingAnimation}
`;

const SuccessIcon = styled(CheckCircleIcon)`
  color: #4caf50;
`;

const StatusText = (props: StatusTextProps) => {
  const { status, submittingText, successText } = props;

  const statusText = {
    default: '',
    submitting: submittingText || 'Submitting...',
    success: successText || 'Done!',
  };

  const statusIcon = {
    default: '',
    submitting: <SubmittingIcon />,
    success: <SuccessIcon />,
  };

  return (
    <Box
      marginTop="10px"
      display="flex"
      flexDirection="row"
    >
      {statusIcon[status]}
      <StatusTypography status={status}>{statusText[status]}</StatusTypography>
    </Box>
  );
};

export default StatusText;
