import styled from 'styled-components';
import Box, { BoxProps } from '@material-ui/core/Box';

interface BorderBoxProps extends BoxProps {
  colour: string;
  font?: string;
}

type ConditionalBorderBoxProps = BorderBoxProps & {
  showBorder: boolean;
  children: React.ReactNode;
};

const StyledBox = styled(Box)<BorderBoxProps>`
  border-radius: 2px;
  border: 1px solid ${({ colour }) => colour};

  ${({ font }) => (font ? `font: ${font};` : '')}

  gap: 8px;
  padding: 16px;
`;

export const ConditionalBorderBox = (props: ConditionalBorderBoxProps) => {
  const { showBorder, children, colour, font } = props;

  return (
    <>
      {showBorder ? (
        <StyledBox
          colour={colour}
          font={font}
        >
          {children}
        </StyledBox>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
