import { useState } from 'react';
import styled from 'styled-components';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useOverrideDetails from '../../hooks/override-details';
import DialogSection from './IndicatorDetailsDialog/DialogSection';
import { IndicatorRecord } from '../../api/indicators/types';
import OverrideRecordCard from './OverrideRecordCard';
import { extractRecordDetails } from './utils';
import theme from '../../theme';

const SearchButton = styled(Button)`
  height: 100%;
  color: ${({ theme }) => theme.colours.teals.teal1};
`;

const OverrideDetails = () => {
  const { records, assign, searchRecords } = useOverrideDetails();
  const [search, setSearch] = useState<string | null>(null);

  const handleOnApply = (record: IndicatorRecord) => {
    assign(record);
    setSearch(null);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearch = () => {
    if (search) {
      searchRecords(search);
    }
  };

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        xs={12}
        item
        container
        alignContent="center"
        justifyContent="space-between"
      >
        <Grid
          item
          spacing={1}
          container
          alignContent="center"
          xs={10}
        >
          <Grid
            xs={4}
            item
          >
            <TextField
              fullWidth
              id="outlined-basic"
              label="Search Record #"
              variant="outlined"
              value={search ?? ''}
              onChange={handleOnChange}
            />
          </Grid>
          <Grid
            xs={6}
            item
          >
            <SearchButton
              variant="outlined"
              color="inherit"
              size="large"
              onClick={handleSearch}
              disabled={!search}
            >
              Search
            </SearchButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        item
      >
        <DialogSection
          noColour
          title="Results"
        >
          {records.length > 0 ? (
            records.map((record, index) => {
              const recordDetails = extractRecordDetails(record);
              return (
                <OverrideRecordCard
                  key={index}
                  recordDetails={recordDetails}
                  actionButtonColour={theme.colours.action.primary}
                  actionButtonIcon={<AddCircleOutlineIcon />}
                  actionButtonText="Add"
                  handleActionButtonClick={() => handleOnApply(record)}
                />
              );
            })
          ) : (
            <Typography>There are no records to show.</Typography>
          )}
        </DialogSection>
      </Grid>
    </Grid>
  );
};

export default OverrideDetails;
