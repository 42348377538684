import React, { useState, useEffect, createContext, useCallback, useContext } from 'react';
import api from '../api';
import { OutagePlan } from '../types/outage-plans';

interface OutagePlansContextData {
  outagePlans: OutagePlan[];
  addOutagePlan: (outageCode: string, status: string) => Promise<void>;
  editOutagePlan: (outageCode: string, status: string, originalOutageCode: string) => Promise<void>;
  deleteOutagePlan: (outageCode: string) => Promise<void>;
  loading: boolean;
  error: string | null;
}

export const OutagePlansContext = createContext<OutagePlansContextData>({
  outagePlans: [],
  addOutagePlan: () => Promise.resolve(),
  editOutagePlan: () => Promise.resolve(),
  deleteOutagePlan: () => Promise.resolve(),
  loading: false,
  error: null,
});

const OutagePlansProvider = (props: React.PropsWithChildren) => {
  const [outagePlans, setOutagePlans] = useState<OutagePlan[]>([{ outage_code: 'default', status: 'Planned' }]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const getOutagePlans = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const newPlans = await api.getOutagePlans();
      if (newPlans.length > 0) {
        setOutagePlans(newPlans);
      } else {
        setError('No Outage Plans found');
      }
    } catch (err) {
      setError('Failed to fetch Outage Plans');
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const addOutagePlan = async (outageCode: string, status: string) => {
    setLoading(true);
    setError(null);
    try {
      await api.addOutagePlan(outageCode, status);
      await getOutagePlans();
    } catch (err) {
      setError('Failed to add Outage Plan');
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const editOutagePlan = async (outageCode: string, status: string, originalOutageCode: string) => {
    setLoading(true);
    setError(null);
    try {
      await api.editOutagePlan(outageCode, status, originalOutageCode);
      await getOutagePlans();
    } catch (err) {
      setError('Failed to edit Outage Plan');
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const deleteOutagePlan = async (outageCode: string) => {
    setLoading(true);
    setError(null);
    try {
      await api.deleteOutagePlan(outageCode);
      await getOutagePlans();
    } catch (err) {
      setError('Failed to delete Outage Plan');
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void getOutagePlans();
  }, [getOutagePlans]);

  return (
    <OutagePlansContext.Provider
      value={{ outagePlans, loading, error, addOutagePlan, editOutagePlan, deleteOutagePlan }}
    >
      {props.children}
    </OutagePlansContext.Provider>
  );
};

export const useOutagePlans = () => {
  const context = useContext(OutagePlansContext);
  if (!context) {
    throw new Error('useOutagePlans must be used within an OutagePlansProvider');
  }
  return context;
};

export default OutagePlansProvider;
