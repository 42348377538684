import { useRiskApproverForm } from '../hooks/useRiskApproverForm';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { UppercaseTitle } from './UppercaseTitle';
import ActionButton from '../../../../components/ActionButton';
import StatusText from '../SubmitStatusText';
import useRiskManagement from '../../../../context/risk-management';
import { LegacyApproverInfo } from './LegacyApproverInfo';
import EscalationSummaryInfo from './EscalationSummaryInfo';
import isFeatureEnabled from '../../../../utils/feature-flags';
import { EscalateIssue } from './EscalateIssue';
import { DecisionTypeInfo } from './DecisionTypeInfo';
import styled from 'styled-components';
import theme from '../../../../theme';
import { ConditionalBorderBox } from '../ConditionalBorderBox';
import { getEscalationRoleLabel, getEscalationNeighbours } from '../hooks/utils';
import EscalationHistory from './EscalationHistory';
import useUsers from '../../../../hooks/users';
import { User } from '../../../../types/user';

const NormalSmallText = styled.span`
  font: ${({ theme }) => theme.fonts.normal14};
`;

const BoldSmallText = styled.span`
  font: ${({ theme }) => theme.fonts.bold14};
`;

type Props = {
  riskManagementId: number;
};

export const RiskApproverForm = ({ riskManagementId }: Props) => {
  const { riskApprovers } = useRiskManagement();
  const { users }: { users: User[] } = useUsers();
  const {
    loading,
    control,
    handleSubmit,
    submitTextStatus,
    errors,
    decisionOptions,
    escalationDecisionOptions,
    yesOrNoOptions,
    watchFlagToCno,
    watchEscalateIssue,
    isReadOnly,
    isSubmitReady,
    isDecisionDescRequired,
    newApproverForm,
    escalations,
    hasEscalations,
    lastEscalatedToRole,
    approvedBy,
  } = useRiskApproverForm(riskManagementId);

  const approvedByName = approvedBy ? users.find(user => user.id === approvedBy)?.name : '';

  return (
    <Box margin="40px">
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={3}
        >
          {!newApproverForm ? (
            <LegacyApproverInfo
              control={control}
              errors={errors}
              decisionOptions={decisionOptions}
              yesOrNoOptions={yesOrNoOptions}
              watchFlagToCno={watchFlagToCno}
              watchEscalateIssue={watchEscalateIssue}
              isReadOnly={isReadOnly}
              isDecisionDescRequired={isDecisionDescRequired}
            />
          ) : (
            <>
              {!hasEscalations ? (
                <Grid
                  item
                  xs={12}
                >
                  <UppercaseTitle>General Information</UppercaseTitle>
                </Grid>
              ) : (
                <EscalationSummaryInfo
                  control={control}
                  errors={errors}
                  isReadOnly={isReadOnly}
                />
              )}
              {hasEscalations && <EscalationHistory escalations={escalations} />}
              <Grid
                item
                xs={12}
              >
                <ConditionalBorderBox
                  showBorder={hasEscalations}
                  colour={isReadOnly ? theme.colours.greys.lightGrey1 : theme.colours.teals.teal1}
                >
                  <Grid
                    container
                    spacing={3}
                  >
                    {hasEscalations && (
                      <Grid
                        item
                        xs={12}
                      >
                        <BoldSmallText>{getEscalationRoleLabel(lastEscalatedToRole)}</BoldSmallText>
                        <NormalSmallText>{approvedBy && ': ' + approvedByName}</NormalSmallText>
                      </Grid>
                    )}
                    {lastEscalatedToRole !== 'cno' && (
                      <EscalateIssue
                        control={control}
                        errors={errors}
                        yesOrNoOptions={yesOrNoOptions}
                        watchEscalateIssue={watchEscalateIssue}
                        isReadOnly={isReadOnly}
                        riskApprovers={riskApprovers}
                        nextRole={getEscalationNeighbours(lastEscalatedToRole).after ?? lastEscalatedToRole}
                      />
                    )}
                    {watchEscalateIssue === 'yes' && !hasEscalations && (
                      <EscalationSummaryInfo
                        control={control}
                        errors={errors}
                        isReadOnly={isReadOnly}
                      />
                    )}
                    {!!watchEscalateIssue && (
                      <DecisionTypeInfo
                        control={control}
                        errors={errors}
                        decisionOptions={decisionOptions}
                        escalationDecisionOptions={escalationDecisionOptions}
                        watchEscalateIssue={watchEscalateIssue}
                        isDecisionDescRequired={isDecisionDescRequired}
                        isReadOnly={isReadOnly}
                        title={lastEscalatedToRole !== 'cno'}
                        escalation={hasEscalations}
                      />
                    )}
                  </Grid>
                </ConditionalBorderBox>
              </Grid>
            </>
          )}
          <Grid
            item
            xs={12}
          >
            <Box marginTop="20px">
              <ActionButton
                color="primary"
                type="submit"
                aria-label="submit"
                disabled={isReadOnly || (!isSubmitReady && isFeatureEnabled('riskEscalationOverhaul')) || loading}
              >
                Submit
              </ActionButton>
              {submitTextStatus !== 'default' && (
                <StatusText
                  status={submitTextStatus}
                  submittingText={
                    watchEscalateIssue === 'yes' ? 'Submitting Risk Escalation...' : 'Submitting Risk Approver Form...'
                  }
                  successText="Done! Sending you back to Risk Approver Page..."
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
