import { useCallback } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import Page from '../../components/Page';
import { WalkdownsProvider } from '../../hooks/walkdowns';
import InprogressPage from './InprogressPage';
import ArchivesPage from './ArchivesPage';
import FilterButtons from './components/FilterButtons';
import { WalkdownTemplatesListProvider } from './hooks/useWalkdownTemplatesList';
import Templates from './templates';
import SingleTemplate from './templates/SingleTemplate';
import { WalkdownTemplateProvider } from './hooks/useWalkdownTemplate';
import { Downloader } from '../../hooks/downloader';
import { MyRecentAssignmentsProvider } from '../../hooks/my-recent-assignments';
import StartWalkdown from './StartWalkdown';
import SingleWalkdown from './SingleWalkdown';
import LegacyRedirect from './components/LegacyRedirect';
import isFeatureEnabled from '../../utils/feature-flags';

const WrappedSingleTemplate = ({ match }: any) => (
  <WalkdownTemplateProvider templateId={match.params.id}>
    <SingleTemplate />
  </WalkdownTemplateProvider>
);

const WalkdownPage = () => {
  const match = useRouteMatch();
  const WalkdownArchiveRedirect = useCallback(() => <Redirect to={`${match.path}/archives`} />, [match]);

  return (
    <Page
      title="Walkdown"
      bodyComponent={<Paper square />}
    >
      {!isFeatureEnabled('isWalkdownsArchived') ? (
        <MyRecentAssignmentsProvider>
          <FilterButtons href={match.path} />
        </MyRecentAssignmentsProvider>
      ) : null}
      <Switch>
        <Route
          exact
          path={match.path}
          component={!isFeatureEnabled('isWalkdownsArchived') ? InprogressPage : WalkdownArchiveRedirect}
        />
        <Route
          exact
          path={`${match.path}/archives`}
          component={ArchivesPage}
        />
        <Route
          path={`${match.path}/templates/view/:id`}
          component={!isFeatureEnabled('isWalkdownsArchived') ? WrappedSingleTemplate : WalkdownArchiveRedirect}
        />
        <Route
          exact
          path={`${match.path}/start-walkdown`}
          component={!isFeatureEnabled('isWalkdownsArchived') ? StartWalkdown : WalkdownArchiveRedirect}
        />
        <Route
          exact
          path={`${match.path}/archives/view/:walkdownId`}
          component={SingleWalkdown}
        />
        <Route
          exact
          path={[`${match.path}/archives/view/:walkdownId`, `${match.path}/in-progress/view/:walkdownId`]}
          component={!isFeatureEnabled('isWalkdownsArchived') ? SingleWalkdown : WalkdownArchiveRedirect}
        />
        <Route
          exact
          path={`${match.path}/view/:walkdownId`}
          render={() => <LegacyRedirect oldPath={match.path} />}
        />
        <Route path={`${match.path}/templates`}>
          {!isFeatureEnabled('isWalkdownsArchived') ? (
            <WalkdownTemplatesListProvider>
              <Templates />
            </WalkdownTemplatesListProvider>
          ) : (
            <WalkdownArchiveRedirect />
          )}
        </Route>
      </Switch>
    </Page>
  );
};

export default () => (
  <Downloader>
    <WalkdownsProvider>
      <WalkdownPage />
    </WalkdownsProvider>
  </Downloader>
);
