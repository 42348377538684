import React from 'react';
import { Grid } from '@material-ui/core';
import { Control, FieldErrors, FieldError } from 'react-hook-form';
import { ControlTextField } from '../../../../form/ControlTextField';
import { RiskApproverFormInputs } from '../../../../types/risk-management';
import { UppercaseTitle } from './UppercaseTitle';

export interface EscalationSummaryInfoProps {
  control: Control<RiskApproverFormInputs>;
  errors: FieldErrors<RiskApproverFormInputs>;
  isReadOnly: boolean;
}

const EscalationSummaryInfo: React.FC<EscalationSummaryInfoProps> = ({ control, errors, isReadOnly }) => {
  const riskEscalationFields: {
    name: keyof RiskApproverFormInputs;
    label: string;
    errors?: FieldError;
  }[] = [
    { name: 'cnoDescription', label: 'Risk Description', errors: errors.cnoDescription },
    { name: 'cnoBridgingStrategy', label: 'Risk Bridging Strategy', errors: errors.cnoBridgingStrategy },
    { name: 'cnoStatusUpdate', label: 'Risk Status Update', errors: errors.cnoStatusUpdate },
    { name: 'riskResponseSummary', label: 'Risk Response Summary', errors: errors.riskResponseSummary },
  ];

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <UppercaseTitle>Summary Information</UppercaseTitle>
      </Grid>
      {riskEscalationFields.map(field => (
        <Grid
          key={field.name}
          item
          xs={12}
        >
          <ControlTextField
            control={control}
            required
            name={field.name}
            error={!!field.errors}
            maxLength={3000}
            multiline={true}
            minRows={6}
            label={field.label}
            readOnly={isReadOnly}
            placeholder="Required (Character Limitation: 3000 characters)"
          />
        </Grid>
      ))}
    </>
  );
};

export default EscalationSummaryInfo;
