import * as actionTypes from '../../../../../state/action-types';
import errorAction from '../../../../../state/utils/error-action';

export const loadRecordDetailsRequest = (indicatorShortName, record) => ({
  type: actionTypes.RECORD_DETAILS_LOAD_RECORD_DETAILS_REQUEST,
  payload: {
    indicatorShortName,
    record,
  },
});

export const loadRecordDetailsSuccess = recordDetails => ({
  type: actionTypes.RECORD_DETAILS_LOAD_RECORD_DETAILS_SUCCESS,
  payload: {
    recordDetails,
  },
});

export const loadRecordDetailsFailure = errorAction(actionTypes.RECORD_DETAILS_LOAD_RECORD_DETAILS_FAILURE);

export const setFilter = (name, value) => ({
  type: actionTypes.RECORD_DETAILS_SET_FILTER,
  payload: {
    name,
    value,
  },
});
