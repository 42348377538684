import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import CarlaText from '../../../../../../components/CarlaText';
import DialogButton from '../../../../../../components/DialogButton';
import CustomTextField from '../../../../../../components/CustomTextField';
import Select from '../../../../../../components/Select';
import { useCallback, useState } from 'react';
import * as uiActions from '../../../../../../state/ui/actions';
import { useDispatch } from 'react-redux';
import { useMSMPrograms } from '../../../../../../context/msm-programs';
import LoadingContainer from '../../../../../../components/LoadingContainer';

type Props = {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const NewMSMProgramDashboard = ({ opened, setOpened }: Props) => {
  const { addMSMProgram, loading, error } = useMSMPrograms();
  const [msmProgram, setMSMProgram] = useState('');
  const [status, setStatus] = useState('');
  const dispatch = useDispatch();

  const handleAdd = useCallback(async () => {
    if (msmProgram && status) {
      try {
        await addMSMProgram(msmProgram, status);
        dispatch(uiActions.genericMessage('Added Successfully'));
        setOpened(false);
      } catch (err) {
        dispatch(uiActions.error('', error ?? 'Failed to add MSM program'));
      }
    } else {
      dispatch(uiActions.error('', 'Please enter valid input'));
    }
    setStatus('');
  }, [msmProgram, status]);

  return (
    <Dialog
      open={opened}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Add new MSM Program</DialogTitle>
      <DialogContent>
        {loading ? (
          <LoadingContainer loading={loading} />
        ) : (
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <CarlaText>Enter MSM program and set status</CarlaText>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <CustomTextField
                label="MSM Program"
                inputProps={{ maxLength: 256 }}
                onChange={e => setMSMProgram(e.target.value)}
              ></CustomTextField>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Select
                fullWidth
                label="Status"
                options={[
                  { label: 'Active', value: 'Active' },
                  { label: 'Non-Active', value: 'Non-Active' },
                ]}
                onChange={e => setStatus(e.target.value)}
                value={status}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <DialogButton
          onClick={() => {
            setStatus('');
            setOpened(false);
          }}
        >
          Cancel
        </DialogButton>
        <DialogButton
          color="primary"
          onClick={handleAdd}
        >
          Submit
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewMSMProgramDashboard;
