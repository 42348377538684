import { Paper } from '@material-ui/core';
import Page from '../../../components/Page';
import { useState } from 'react';
import { RiskTable } from '../../../components/RiskTable';
import useRiskManagement from '../../../context/risk-management';

export default () => {
  const [elementId, setElementId] = useState<string>('');
  const { currentRiskManagementUserRoles, riskApprovers, myElements } = useRiskManagement();
  return (
    <Page
      title="Risk Review Page"
      bodyComponent={<Paper square />}
    >
      <RiskTable
        elementId={elementId}
        setElementId={setElementId}
        state={'approval'}
        currentRiskManagementUserRoles={currentRiskManagementUserRoles}
        riskApprovers={riskApprovers}
        myElements={myElements}
      />
    </Page>
  );
};
