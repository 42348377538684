import { useEffect } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';

import useForm from '../../hooks/form';
import CustomTextField from '../CustomTextField';
import NamedValue from './components/NamedValue';
import ColourLegend from './components/ColourLegend';
import { OverrideValue } from '../../hooks/override-value';
import ActionButton from '../ActionButton';

const ScoreField = styled(CustomTextField)`
  width: 105px;
`;

const formConfig = {
  calculatedScore: {
    disabled: true,
  },
  score: {},
  comment: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Add comment',
      },
    },
  },
};

interface Props {
  readonly?: boolean;
  overrideValue: OverrideValue;
  onSave(score: number, comment: string): void;
  onClear(): void;
  onSetFormDirty(isDirty: boolean): void;
}

interface FormInitialValue<S> {
  calculatedScore: number;
  score: S;
  comment: string;
}

const initialValues = (overrideValue: OverrideValue): FormInitialValue<number> => ({
  calculatedScore: overrideValue.score,
  score: R.pathOr(overrideValue.score, ['override', 'score'], overrideValue),
  comment: R.pathOr('', ['override', 'comment'], overrideValue),
});

const OverrideIndicatorDialog = ({ readonly, overrideValue, onSave, onClear, onSetFormDirty }: Props) => {
  const handleSave = (values: FormInitialValue<string>) => {
    const score = parseInt(values.score, 10);
    if (score === overrideValue.score) {
      onClear();
    } else {
      onSave(score, values.comment);
    }
  };
  const { submit, isDirty, propsForField } = useForm(formConfig, initialValues(overrideValue), handleSave);

  useEffect(() => {
    onSetFormDirty(isDirty);
  }, [isDirty]);

  return (
    <Grid
      container
      spacing={2}
      style={{ height: '100%' }}
    >
      <Grid
        container
        item
        xs={12}
      >
        <Grid
          container
          item
          xs={12}
          direction="row"
          spacing={2}
          justifyContent="space-between"
          style={{ marginBottom: '2%' }}
        >
          <Grid
            container
            item
            xs={4}
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <NamedValue
                title="Indicator"
                value={overrideValue.indicatorName}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <NamedValue
                title="Unit"
                value={overrideValue.elementUnit}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={8}
          >
            <ColourLegend colourCurve={overrideValue.colourCurve} />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={6}
          spacing={2}
        >
          <Grid
            container
            item
            direction="row"
            spacing={2}
            xs={12}
          >
            <Grid
              item
              xs={4}
            >
              <ScoreField
                label="Calculated"
                fullWidth
                {...propsForField('calculatedScore')}
                disabled={readonly}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <ScoreField
                label="Override"
                fullWidth
                type="number"
                inputProps={{ min: 0 }}
                {...propsForField('score')}
                disabled={readonly}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={10}
          >
            <CustomTextField
              label="Add comment"
              fullWidth
              inputProps={{ minLength: 1 }}
              maxRows={6}
              multiline
              placeholder="Required"
              {...propsForField('comment')}
              disabled={parseInt(propsForField('score').value, 10) === overrideValue.score || readonly}
            />
          </Grid>
        </Grid>
      </Grid>
      {R.isNil(readonly) && (
        <Grid
          container
          item
          alignItems="flex-end"
          spacing={2}
        >
          <Grid
            container
            item
            xs={1}
          >
            <ActionButton
              color="secondary"
              variant="text"
              onClick={submit}
            >
              Save
            </ActionButton>
          </Grid>
          <Grid
            container
            item
            xs={4}
          >
            {!R.isNil(overrideValue.override) && <ActionButton onClick={onClear}>Clear Override</ActionButton>}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default OverrideIndicatorDialog;
