import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import UnstyledHomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import UnstyledTimelineIcon from '@material-ui/icons/Timeline';
import UnstyledFastForwardIcon from '@material-ui/icons/FastForward';
import UnstyledAssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import UnstyledLocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import UnstyledLayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import UnstyledSupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import UnstyledRateReviewIcon from '@material-ui/icons/RateReview';
import UnstyledPieChartIcon from '@material-ui/icons/PieChart';
import SvgIcon from '@material-ui/core/SvgIcon';

import DashboardPage from '../../pages/dashboard/DashboardPage';
import HealthReportsPage from '../../pages/health-reports/HealthReportsPage';
import LiveReportsPage from '../../pages/live-reports';
import AdminPage from '../../pages/admin/AdminPage';
import SettingsPage from '../../pages/settings/SettingsPage';
import DashboardsPage from '../../pages/dashboards/DashboardsPage';
import ComingSoonPage from '../../pages/coming-soon/ComingSoonPage';
import DeferralsPage from '../../pages/deferrals/DeferralsPage';
import ApprovalsPage from '../../pages/approvals/ApprovalsPage';
import WalkdownPage from '../../pages/walkdown/WalkdownPage';
import BusinessEquipRisksPage from '../../pages/business-equip-risks/BusinessEquipRisksPage';
import ForcedOutagesPage from '../../pages/forced-outages/ForcedOutagesPage';
import InsightsPage from '../../pages/insights/InsightsPage';

import { ReactComponent as TourIcon } from '../../assets/tour-24px.svg';
import { ReactComponent as ForcedOutageIcon } from '../../assets/forced-outage-icon.svg';
import isFeatureEnabled from '../../utils/feature-flags';
import { USER_ROLES } from '../../types/risk-management';
import { forRole, forRoles } from '../../auth/roles';

const HomeOutlinedIcon = styled(UnstyledHomeOutlinedIcon)`
  color: ${({ theme }) => theme.colours.white};
`;

const TimelineIcon = styled(UnstyledTimelineIcon)`
  color: ${({ theme }) => theme.colours.white};
`;

const FastForwardIcon = styled(UnstyledFastForwardIcon)`
  color: ${({ theme }) => theme.colours.white};
`;

const PieChartIconIcon = styled(UnstyledPieChartIcon)`
  color: ${({ theme }) => theme.colours.white};
`;

const AssignmentOutlinedIcon = styled(UnstyledAssignmentOutlinedIcon)`
  color: ${({ theme }) => theme.colours.white};
`;

const LocalHospitalOutlinedIcon = styled(UnstyledLocalHospitalOutlinedIcon)`
  color: ${({ theme }) => theme.colours.white};
`;

const LayersOutlinedIcon = styled(UnstyledLayersOutlinedIcon)`
  color: ${({ theme }) => theme.colours.white};
`;

const SupervisorAccountOutlinedIcon = styled(UnstyledSupervisorAccountOutlinedIcon)`
  color: ${({ theme }) => theme.colours.white};
`;

// Known bug with Material v4, see https://github.com/mui/material-ui/issues/15759#issuecomment-493994852
const CustomSvgIcon = styled(SvgIcon)`
  color: ${({ theme }) => theme.colours.white};
` as typeof SvgIcon;

const RateReviewIcon = styled(UnstyledRateReviewIcon)`
  color: ${({ theme }) => theme.colours.white};
`;

const navItem = (label: string, to: string, icon?: typeof SvgIcon) => ({
  type: 'nav',
  label,
  to,
  icon,
});

const subMenu = (label: string, to: string, icon?: typeof SvgIcon, children?: any) => ({
  type: 'subMenu',
  label,
  to,
  icon,
  children,
});

const actionItem = (label: string, action: any) => ({
  type: 'action',
  label,
  action,
});

const DASHBOARD_PATH = '/app/dashboard';
const LIVE_REPORTS_PATH = '/app/live';
const HEALTH_REPORTS_PATH = '/app/health-reports';
const POWER_BI_PATH = '/app/power-bi';
const ADMIN_PATH = '/app/admin';
const ADMIN_USERS_PATH = '/app/admin/users';
const ADMIN_CATEGORIES_PATH = '/app/admin/admin-categories';
const ADMIN_DASHBOARDS_PATH = '/app/admin/dashboards';
const ADMIN_ELEMENTS_PATH = '/app/admin/elements';
const ADMIN_DESIGN_SYSTEM_PATH = '/app/admin/design-system';
const ADMIN_MCR_UNITS_PATH = '/app/admin/mcr-units';
const ADMIN_RECENT_APPROVED_REPORTS_PATH = '/app/admin/recent-reports';
const PROFILE_PATH = '/app/profile';
const COMING_SOON_PATH = '/app/coming-soon';
const DEFERRALS_PATH = '/app/deferrals';
const APPROVALS_PATH = '/app/approvals';
const REPORT_REVIEW_PATH = `${APPROVALS_PATH}/report-review`;
const BUSINESS_EQUIPMENT_RISKS_PATH = '/app/business-equipment-risks';
const FORCED_OUTAGES_PATH = '/app/forced-outages';
const WALKDOWN_APPROVALS_PATH = `${APPROVALS_PATH}/walkdown-review`;
const DEFERRAL_APPROVALS_PATH = `${APPROVALS_PATH}/deferrals`;
const RISK_REVIEW_PATH = `${BUSINESS_EQUIPMENT_RISKS_PATH}/risk-review`;
const DEFERRAL_REQUEST_PATH = `${DEFERRALS_PATH}/request`;
const DEFERRAL_HISTORY_PATH = `${DEFERRALS_PATH}/history`;
const WALKDOWN_PATH = '/app/walkdown';
const RISK_MANAGEMENT = '/app/admin/risk-management';
const BUSINESS_EQUIPMENT_RISK = '/app/admin/business-equipment-risks';
const INSIGHTS_PATH = '/app/insights';

export const menuItems = (onSignOut: any, currentUserRoles: USER_ROLES[]) =>
  [
    navItem('Home', DASHBOARD_PATH, HomeOutlinedIcon),
    navItem('Health Reports', HEALTH_REPORTS_PATH, LocalHospitalOutlinedIcon),
    navItem('Live Reports', LIVE_REPORTS_PATH, TimelineIcon),
    subMenu(
      'Insights',
      INSIGHTS_PATH,
      PieChartIconIcon,
      [
        navItem('Health Trends', `${INSIGHTS_PATH}/health-trends`),
        navItem('Forecast', `${INSIGHTS_PATH}/forecast`),
      ].filter(x => !R.isNil(x))
    ),
    ...forRoles(
      ['FLM', 'Admin', 'Developer'],
      subMenu(
        'Approvals',
        APPROVALS_PATH,
        RateReviewIcon,
        [
          navItem('Report Review', REPORT_REVIEW_PATH),
          navItem('Deferral Review', DEFERRAL_APPROVALS_PATH),
          ...(!isFeatureEnabled('isWalkdownsArchived') ? [navItem('Walkdown Review', WALKDOWN_APPROVALS_PATH)] : []),
        ].filter(x => !R.isNil(x))
      )
    ),
    isFeatureEnabled('forcedOutages')
      ? navItem('Forced Outages', FORCED_OUTAGES_PATH, () => (
          <CustomSvgIcon
            component={ForcedOutageIcon}
            viewBox="0 0 96 96"
          />
        ))
      : null,
    isFeatureEnabled('riskManagement')
      ? subMenu(
          'Business/Equip Risk',
          BUSINESS_EQUIPMENT_RISKS_PATH,
          AssignmentOutlinedIcon,
          [
            navItem('Risk Management', BUSINESS_EQUIPMENT_RISKS_PATH),
            currentUserRoles.includes(USER_ROLES.APPROVER) || currentUserRoles.includes(USER_ROLES.ENVIRONMENT_GROUP)
              ? navItem('Risk Approval', RISK_REVIEW_PATH)
              : null,
          ].filter(x => !R.isNil(x))
        )
      : null,
    navItem('Dashboards', POWER_BI_PATH, LayersOutlinedIcon),
    ...(!isFeatureEnabled('isWalkdownsArchived')
      ? [navItem('Walkdowns', WALKDOWN_PATH, AssignmentOutlinedIcon)]
      : [navItem('Walkdowns', `${WALKDOWN_PATH}/archives`, AssignmentOutlinedIcon)]),
    subMenu('Deferrals', DEFERRALS_PATH, FastForwardIcon, [
      navItem('Request New', DEFERRAL_REQUEST_PATH),
      navItem('View Previous', DEFERRAL_HISTORY_PATH),
    ]),
    ...forRole(
      'Admin',
      subMenu(
        'Admin',
        ADMIN_PATH,
        SupervisorAccountOutlinedIcon,
        [
          navItem('Users', ADMIN_USERS_PATH),
          navItem('Admin Categories', ADMIN_CATEGORIES_PATH),
          navItem('Dashboards', ADMIN_DASHBOARDS_PATH),
          navItem('Elements', ADMIN_ELEMENTS_PATH),
          navItem('Design System', ADMIN_DESIGN_SYSTEM_PATH),
          navItem('MCR Units', ADMIN_MCR_UNITS_PATH),
          navItem('Reopen Reports', ADMIN_RECENT_APPROVED_REPORTS_PATH),
          isFeatureEnabled('riskManagement') ? navItem('Risk Management', RISK_MANAGEMENT) : null,
          isFeatureEnabled('riskManagement') ? navItem('Business Equipment Risk Users', BUSINESS_EQUIPMENT_RISK) : null,
        ].filter(x => !R.isNil(x))
      )
    ),
    isFeatureEnabled('comingsoon')
      ? navItem('Coming Soon', COMING_SOON_PATH, () => <CustomSvgIcon component={TourIcon} />)
      : null,
    actionItem('Sign-out', onSignOut),
  ].filter(x => !R.isNil(x));

const route = (path: any, component: any) => ({
  path,
  component,
});

export const routes = [
  route(DASHBOARD_PATH, DashboardPage),
  route(HEALTH_REPORTS_PATH, HealthReportsPage),
  route(LIVE_REPORTS_PATH, LiveReportsPage),
  route(ADMIN_PATH, AdminPage),
  route(PROFILE_PATH, SettingsPage),
  route(POWER_BI_PATH, DashboardsPage),
  route(COMING_SOON_PATH, ComingSoonPage),
  route(APPROVALS_PATH, ApprovalsPage),
  route(DEFERRALS_PATH, DeferralsPage),
  route(WALKDOWN_PATH, WalkdownPage),
  route(BUSINESS_EQUIPMENT_RISKS_PATH, BusinessEquipRisksPage),
  isFeatureEnabled('forcedOutages') && route(FORCED_OUTAGES_PATH, ForcedOutagesPage),
  route(INSIGHTS_PATH, InsightsPage),
];
