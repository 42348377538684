import * as R from 'ramda';
import React, { useState, useEffect, useCallback, createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';
import * as scorecardActions from '../state/scorecard/actions';
import * as uiActions from '../state/ui/actions';
import Api from '../api';
import useIndicator from './indicator';
import { ElementId } from '../types/app';
import { ManualAssociatedRecord } from '../api/elements/types';
import { IndicatorRecord } from '../api/indicators/types';
import useIndicatorRecordsByQuarter from './indicator-records-by-quarter';

interface OverrideDetailsContextValue {
  associatedOverrideRecords: ManualAssociatedRecord[];
  records: IndicatorRecord[];
  shortName: string;
  assign: (record: IndicatorRecord) => Promise<void>;
  searchRecords: (recordId: string) => Promise<void>;
  deleteAssociatedRecord: (associatedRecord: ManualAssociatedRecord) => Promise<void>;
}

const OverrideDetailsContext = createContext<OverrideDetailsContextValue | null>(null as any);

const useOverrideDetails = () => {
  const reportIndicators = useContext(OverrideDetailsContext);

  if (R.isNil(reportIndicators)) {
    throw new Error('useOverrideDetails must be used inside OverrideDetailsProvider');
  }

  return reportIndicators;
};

type ProviderProps = React.PropsWithChildren<{
  indicatorId: number;
  facilityId: string;
  elementUnit: string;
  elementType?: string;
  elementId: ElementId;
  year: number;
  quarter: number;
}>;

export const OverrideDetailsProvider = ({
  children,
  indicatorId,
  elementUnit,
  elementId,
  elementType,
  year,
  quarter,
}: ProviderProps) => {
  const dispatch = useDispatch();
  const [records, setRecords] = useState<IndicatorRecord[]>([]);
  const [associatedOverrideRecords, setAssociatedOverrideRecords] = useState<ManualAssociatedRecord[]>([]);
  const { refreshRecords } = useIndicatorRecordsByQuarter(elementId, year, quarter, indicatorId, undefined, false);

  const { shortName } = useIndicator();

  const loadAssociatedOverrideRecords = (indicatorId: number, elementId: ElementId, elementUnit: string) =>
    Api.getAssignedIndicatorRecords(indicatorId, elementId, elementUnit).then(setAssociatedOverrideRecords);

  useEffect(() => {
    void loadAssociatedOverrideRecords(indicatorId, elementId, elementUnit);
  }, [elementId, indicatorId, elementUnit]);

  const refreshRecordData = useCallback(async () => {
    // Refresh both associated override records and the "Associated Records" tables
    void loadAssociatedOverrideRecords(indicatorId, elementId, elementUnit);
    refreshRecords();

    // Trigger scorecard refresh
    dispatch(scorecardActions.loadScorecardRequest(elementId, year, quarter));
  }, [elementId, indicatorId, elementUnit]);

  const assign = useCallback(
    async (selectedRecord: IndicatorRecord) => {
      await Api.assignIndicatorRecord(indicatorId, elementId, elementUnit, shortName, selectedRecord);
      dispatch(uiActions.genericMessage('Record added.'));

      // Clear search results and refresh data to include new record
      setRecords([]);
      void refreshRecordData();
    },
    [elementId, elementUnit, indicatorId, shortName]
  );

  const searchRecords = useCallback(
    async (queryId: string) => {
      try {
        const res = await Api.getIndicatorRecords(elementId, indicatorId, queryId);
        dispatch(uiActions.genericMessage('Records found.'));
        setRecords(res.slice(0, 1));
      } catch (e: any) {
        dispatch(uiActions.error('', e.response.data.message));
      }
    },
    [indicatorId, elementType, elementId]
  );

  const deleteAssociatedRecord = useCallback(
    async (record: ManualAssociatedRecord) => {
      const { crNumber, pmNumber, workOrderNumber, workRequestNumber } = record;
      await Api.removeAssociatedIndicatorRecord(
        indicatorId,
        elementId,
        elementUnit,
        crNumber,
        pmNumber,
        workOrderNumber,
        workRequestNumber
      );
      dispatch(uiActions.genericMessage('Record removed.'));

      // Refresh data to remove record
      void refreshRecordData();
    },
    [elementId, indicatorId, elementUnit]
  );

  const value = {
    associatedOverrideRecords,
    records,
    shortName,
    assign,
    searchRecords,
    deleteAssociatedRecord,
  };

  return <OverrideDetailsContext.Provider value={value}>{children}</OverrideDetailsContext.Provider>;
};

export default useOverrideDetails;
