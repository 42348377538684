import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../state/indicator-records/actions';
import * as selectors from '../state/indicator-records/selectors';

const useIndicatorRecordsByQuarter = (elementId, year, quarter, indicatorId, applicable = undefined, load = true) => {
  const dispatch = useDispatch();

  const loadIndicatorRecords = useCallback(() => {
    dispatch(actions.loadByQuarterRequest(elementId, year, quarter, indicatorId, applicable));
  }, [dispatch, elementId, year, quarter, indicatorId, applicable]);

  useEffect(() => {
    if (load && indicatorId) {
      loadIndicatorRecords();
    }
  }, [load, loadIndicatorRecords, indicatorId]);

  const records = useSelector(state => selectors.getRecordsByQuarter(state, elementId, year, quarter, indicatorId));
  const isLoading = useSelector(state => selectors.isLoadingByQuarter(state, elementId, year, quarter, indicatorId));

  return {
    records,
    isLoading,
    refreshRecords: loadIndicatorRecords,
  };
};

export default useIndicatorRecordsByQuarter;
