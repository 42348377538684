/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import MUISelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import useAllAttachmentTypes from '../../../../../hooks/all-attachment-types';
import isFeatureEnabled from '../../../../../utils/feature-flags';

const Select = styled(MUISelect)`
  width: 160px;
`;

interface Props {
  className?: string;
  value: string;
  onChange: (fileType: string) => void;
}

const FileTypeSelector = ({ className, value, onChange }: Props) => {
  const { attachmentTypes } = useAllAttachmentTypes();

  const handleChange = (e: React.ChangeEvent<{ name?: string; value: any }>) => {
    onChange(e.target.value);
  };

  const preFilteredAttachmentTypes = isFeatureEnabled('walkdownFileAttachmentType')
    ? attachmentTypes
    : attachmentTypes.filter((att: any) => att.id !== 'walkdown');

  const fileTypeItems = preFilteredAttachmentTypes.map((attachmentType: any) => (
    <MenuItem
      key={attachmentType.id}
      value={attachmentType.id}
    >
      {attachmentType.name}
    </MenuItem>
  ));

  return (
    <Select
      className={className}
      displayEmpty
      value={R.isNil(value) || R.isEmpty(attachmentTypes) ? '' : value}
      onChange={handleChange}
    >
      <MenuItem value="">Select File Type</MenuItem>
      {fileTypeItems}
    </Select>
  );
};

export default FileTypeSelector;
