import {
  RiskEscalationRoles,
  RiskEscalationTableFields,
  DecisionTypeRiskApprover,
} from '../../../../types/risk-management';
import Grid from '@material-ui/core/Grid';
import { getEscalationRoleLabel, getDecisionOption, getEscalationNeighbours } from '../hooks/utils';
import useUsers from '../../../../hooks/users';
import { User } from '../../../../types/user';
import { dateToString } from '../../../../utils/date-format';
import theme from '../../../../theme';
import styled from 'styled-components';
import { ConditionalBorderBox } from '../ConditionalBorderBox';
import Box from '@material-ui/core/Box';
import { UppercaseTitle } from './UppercaseTitle';

const BoldText = styled.span`
  font: ${({ theme }) => theme.fonts.bold14};
`;

const LineBox = styled(Box)`
  height: 24px;
  border-right: 2px dotted ${({ theme }) => theme.colours.greys.lightGrey1};
`;

interface EscalationHistoryProps {
  escalations: RiskEscalationTableFields[];
}

interface EscalationHistorySectionProps {
  escalatedBy: string;
  escalatedTo: string;
  escalatedToRole: RiskEscalationRoles;
  escalationDate: string;
  escalationJustification: string;
  recommendedDecision: DecisionTypeRiskApprover | null;
  recommendedDecisionJustification: string;
}

const EscalationHistorySection = (props: EscalationHistorySectionProps) => {
  const { users }: { users: User[] } = useUsers();
  const {
    escalatedBy,
    escalatedTo,
    escalatedToRole,
    escalationDate,
    escalationJustification,
    recommendedDecision,
    recommendedDecisionJustification,
  } = props;

  const escalatedByRole = getEscalationNeighbours(escalatedToRole).before ?? escalatedToRole;
  const escalatedByName = users.find(user => user.id === escalatedBy)?.name;
  const escalatedToName = users.find(user => user.id === escalatedTo)?.name;

  const recommendedDecisionLabel = recommendedDecision !== null ? getDecisionOption(recommendedDecision) : '';
  const escalationDateFormatted = escalationDate !== '' ? dateToString(new Date(escalationDate)) : escalationDate;

  return (
    <ConditionalBorderBox
      colour={theme.colours.greys.lightGrey1}
      showBorder
      font={theme.fonts.normal14}
    >
      <p style={{ marginBlockStart: '0em' }}>
        <BoldText>{getEscalationRoleLabel(escalatedByRole)}: </BoldText>
        {escalatedByName}
        <br />
        <BoldText>Escalation Date: </BoldText>
        {escalationDateFormatted}
      </p>
      <p>
        <BoldText>Escalated To Role: </BoldText>
        {getEscalationRoleLabel(escalatedToRole)}
        <br />
        <BoldText>Escalated To: </BoldText>
        {escalatedToName}
      </p>
      <p>
        <BoldText>Reason for Escalating:</BoldText>
        <br />
        {escalationJustification}
      </p>
      <p style={{ marginBlockEnd: '0em' }}>
        <BoldText>Recommended Decision Type: </BoldText>
        {recommendedDecisionLabel}
        <br />
        <BoldText>Recommended Decision Explanation:</BoldText>
        <br />
        {recommendedDecisionJustification}
      </p>
    </ConditionalBorderBox>
  );
};

const EscalationHistory = (props: EscalationHistoryProps) => {
  const { escalations } = props;

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <UppercaseTitle>Escalation Information</UppercaseTitle>
      </Grid>
      <Grid
        item
        xs={12}
      >
        {escalations.map((escalation, index) => (
          <>
            <EscalationHistorySection
              key={escalation.createdAt}
              escalatedBy={escalation.createdBy}
              escalatedTo={escalation.escalateTo}
              escalatedToRole={escalation.escalateToRole}
              escalationDate={escalation.createdAt}
              escalationJustification={escalation.escalationRationale}
              recommendedDecision={escalation.recommendedDecisionType}
              recommendedDecisionJustification={escalation.recommendedDecisionTypeDescription}
            />
            {index !== escalations.length - 1 && <LineBox width="50%" />}
          </>
        ))}
      </Grid>
    </>
  );
};

export default EscalationHistory;
