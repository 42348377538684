import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { RiskEnvironmentGroupFormInputs } from '../../../../types/risk-management';
import { ControlCheckboxes } from '../../../../form/ControlCheckboxes';
import { ControlSelect } from '../../../../form/ControlSelect';
import { WrappedInput } from './WrappedInput';
import { ControlTextField } from '../../../../form/ControlTextField';
import { ControlRadio } from '../../../../form/ControlRadio';
import { StyledTypography } from './StyledTypography';
import { UppercaseTitle } from './UppercaseTitle';
import { ControlCheckbox } from '../../../../form/ControlCheckbox';
import { useRiskEnvironmentGroupForm } from '../hooks/useRiskEnvironmentGroupForm';
import ActionButton from '../../../../components/ActionButton';
import useRiskManagement from '../../../../context/risk-management';
import StatusText from '../SubmitStatusText';

type Props = {
  riskManagementId: number;
  status: string;
};

const RiskEnvironmentGroupFormData: React.FC<Props> = ({ riskManagementId, status }: Props) => {
  const {
    loading,
    environmentCategories,
    significantEnvironmentalActivities,
    consequenceOptions,
    probabilityOptions,
    probability,
    consequence,
    handleSubmit,
    submitTextStatus,
    control,
    errors,
    isReadOnly,
    saveDraft,
    watchEnvTeamContacts,
    watchEnvConsequenceDescription,
  } = useRiskEnvironmentGroupForm(riskManagementId, status);
  const { riskEnvGroupUsers } = useRiskManagement();

  return (
    <Box padding="40px">
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <WrappedInput>
              <ControlSelect<RiskEnvironmentGroupFormInputs>
                control={control}
                name="envTeamContacts"
                label="Environmental Team Contacts"
                options={riskEnvGroupUsers}
                required={true}
                error={!!errors.envTeamContacts}
                disabled={isReadOnly}
              />
            </WrappedInput>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
          >
            <UppercaseTitle>General Environmental Categories:</UppercaseTitle>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <ControlCheckboxes
              control={control}
              name="environmentCategories"
              checkboxOptions={environmentCategories}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
          >
            <UppercaseTitle>Significant Environment Actives/Aspects (SEA):</UppercaseTitle>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <ControlCheckboxes
              control={control}
              name="significantEnvironmentalActivities"
              checkboxOptions={significantEnvironmentalActivities}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <WrappedInput>
              <ControlTextField
                name="envConsequenceDescription"
                control={control}
                label="Describe the environmental consequence?"
                minLength={1}
                maxLength={3000}
                multiline={true}
                fullWidth={true}
                minRows={8}
                required={true}
                placeholder="(Character Limitation: 3000 characters)"
                error={!!errors.envConsequenceDescription}
                disabled={isReadOnly}
              />
            </WrappedInput>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <WrappedInput>
              <ControlSelect<RiskEnvironmentGroupFormInputs>
                control={control}
                name="consequence"
                label="Consequence"
                options={consequenceOptions}
                required={true}
                error={!!errors.consequence}
                disabled={isReadOnly}
                additionalRules={{ min: 0, max: 5 }}
              />
            </WrappedInput>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <WrappedInput>
              <ControlSelect<RiskEnvironmentGroupFormInputs>
                control={control}
                name="probability"
                label="Probability"
                options={probabilityOptions}
                required={true}
                error={!!errors.probability}
                disabled={isReadOnly}
                additionalRules={{ min: 0, max: 5 }}
              />
            </WrappedInput>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
          >
            <StyledTypography>Enviro Net Ranking</StyledTypography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            {probability * consequence}
            {/* Not working right now */}
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
          >
            <ControlCheckbox
              name="continuousMonitoring"
              label="Keep open for continuous monitoring?"
              control={control}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <ControlRadio
              name="peerGroupOversight"
              control={control}
              required={true}
              error={!!errors.peerGroupOversight}
              label="Flag for Env peer group oversight"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
                { label: 'N/A', value: 'N/A' },
              ]}
              disabled={isReadOnly}
            />
          </Grid>
        </Grid>
        <Box marginTop="40px">
          <ActionButton
            onClick={saveDraft}
            disabled={isReadOnly}
          >
            Save Draft
          </ActionButton>
        </Box>

        <Box marginTop="20px">
          <ActionButton
            color="primary"
            type="submit"
            aria-label="submit"
            disabled={isReadOnly || !watchEnvTeamContacts || !watchEnvConsequenceDescription || loading}
          >
            Submit
          </ActionButton>
          {submitTextStatus !== 'default' && (
            <StatusText
              status={submitTextStatus}
              submittingText="Submitting Risk Environment Group Form..."
              successText="Done! Sending you back to Risk Page..."
            />
          )}
        </Box>
      </form>
    </Box>
  );
};

export default RiskEnvironmentGroupFormData;
