import React, { useCallback } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import moment from 'moment';
import useForm from '../../hooks/form';
import useConditionalConfirm from '../../hooks/conditional-confirm';
import OkayCancelDialog from '../OkayCancelDialog';
import UnstyledActionForm from './ActionForm';
import UnstyledFormDialog from '../FormDialog';
import useElementIndicators from '../../hooks/element-indicators';
import { ActionProvider } from '../../hooks/action';
import { ReportIndicatorsProvider } from '../../hooks/report-indicators-provider';
import { AllReferenceTypesProvider } from '../../hooks/all-reference-types';

const ActionForm = styled(UnstyledActionForm)``;

const FormDialog = styled(UnstyledFormDialog)`
  .MuiDialogContent-root {
    min-height: 600px;
  }

  ${ActionForm} {
    min-width: 1035px;
  }
`;

const formConfig = {
  id: {},
  elementId: {},
  facilityId: {},
  description: {
    validate: {
      presence: {
        allowEmpty: true,
      },
    },
  },
  ownerName: {
    validate: {
      presence: {
        allowEmpty: true,
      },
    },
  },
  targetDate: {
    mapValueFn: R.identity,
    validate: {
      presence: {
        allowEmpty: true,
        message: 'Set a target date',
      },
      datetime: {
        message: 'Must be a valid date',
      },
    },
  },
  phcEndorsementDate: {
    mapValueFn: (_: any, value: any) => (R.isNil(value) ? null : moment(value, 'YYYY-MM-DD').toDate()),
    validate: {
      optionalDatetime: {
        message: 'Must be a valid date',
      },
    },
  },
  relatedRiskScore: {},
  expectedOutcomes: {
    list: {
      changeType: {},
      indicatorId: {},
      amount: {},
    },
    validate: {
      presence: {
        allowEmpty: true,
      },
    },
  },
  dateWorkFinished: {
    mapValueFn: R.identity,
    validate: {
      optionalDatetime: {
        message: 'Must be a valid date',
      },
    },
  },
  references: {
    list: {
      typeId: {},
      referenceNumber: {},
    },
    validate: {
      nonEmptyArray: {
        message: 'Add at least one reference',
      },
    },
  },
  systemHealthFocusRating: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Choose Rating',
      },
    },
  },
  category: {
    mapValueFn: R.identity,
  },
};

const initialValues = (action: any, relatedRisk?: number | null) => {
  return {
    description: R.propOr('', 'description', action),
    ownerName: R.propOr('', 'ownerName', action),
    targetDate: R.propOr('', 'targetDate', action),
    phcEndorsementDate: R.propOr(null, 'phcEndorsementDate', action),
    relatedRiskScore: relatedRisk || R.propOr('', 'relatedRiskScore', action),
    expectedOutcomes: R.propOr([], 'expectedOutcomes', action),
    references: R.propOr([], 'references', action),
    systemHealthFocusRating: R.propOr('sh1', 'systemHealthFocusRating', action),
    category: R.propOr('', 'category', action),
    dateWorkFinished: R.propOr(null, 'dateWorkFinished', action),
  };
};

const withProgFormConfig = (_formConfig: any, elementType: string): any => {
  if (elementType !== 'program') return _formConfig;
  const { ...updatedFormConfig } = _formConfig;
  return {
    ...updatedFormConfig,
    expectedOutcomes: {
      list: {
        indicatorId: {},
        unit: '',
        colourStatus: '',
      },
    },
  };
};

const withProgInitValues = (initValues: any, elementType: string): any => {
  if (elementType !== 'program') return initValues;
  const { ...updatedInitValues } = initValues;
  return updatedInitValues;
};

interface Props {
  action: any;
  relatedRisk?: number | null;
  onSave(action: any): void;
  onCancel(): void;
}

const ActionDialog = ({ action, relatedRisk = null, onSave, onCancel }: Props) => {
  const handleSave = useCallback(
    (values: any) => {
      const sourceSystem = relatedRisk ? 'Risk' : 'Health';
      onSave({
        ...values,
        id: action.id,
        elementId: action.elementId,
        facilityId: action.facilityId,
        source: action.id ? action.source : sourceSystem,
      });
    },
    [action, onSave]
  );

  const progFormConfig = withProgFormConfig(formConfig, action.elementType);
  const progInitValues = withProgInitValues(initialValues(action, relatedRisk), action.elementType);

  const { submit, isDirty, propsForField, propsForListField, values } = useForm(
    progFormConfig,
    progInitValues,
    handleSave
  );

  const {
    confirming,
    confirm: confirmCancel,
    onConfirm: onConfirmCancel,
    onCancel: onCancelConfirm,
  } = useConditionalConfirm(onCancel, isDirty);

  const { elementIndicators, elementIndicatorsById } = useElementIndicators();

  return (
    <FormDialog
      title="Action Item Details"
      onSave={submit}
      onCancel={confirmCancel}
    >
      <ActionProvider action={action}>
        <ReportIndicatorsProvider
          reportIndicators={elementIndicators}
          reportIndicatorsById={elementIndicatorsById}
        >
          <AllReferenceTypesProvider>
            <ActionForm
              elementId={action.elementId}
              propsForField={propsForField}
              propsForListField={propsForListField}
              disableRelatedRisk={!!relatedRisk}
              values={values}
            />
          </AllReferenceTypesProvider>
        </ReportIndicatorsProvider>
      </ActionProvider>
      {confirming && (
        <OkayCancelDialog
          title="Continue Without Saving?"
          okayLabel="Continue"
          onOkay={onConfirmCancel}
          onCancel={onCancelConfirm}
        >
          If you choose to continue, you will lose the data you have entered.
        </OkayCancelDialog>
      )}
    </FormDialog>
  );
};

export default ActionDialog;
