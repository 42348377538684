import React, { useState, useEffect, createContext, useCallback, useContext } from 'react';
import api from '../api';
import { type BusinessObjective } from '../types/business-objectives';

type LoadingStatusState = {
  isLoading: boolean;
  error: null | Error;
};
export type BusinessObjectiveContextType = LoadingStatusState & {
  businessObjectives: BusinessObjective[];
  addBusinessObjective: (title: string, status: string) => Promise<void>;
  editBusinessObjective: (title: string, status: string, originalTitle: string) => Promise<void>;
  deleteBusinessObjective: (title: string) => Promise<void>;
};
export const BusinessObjectivesContext = createContext<BusinessObjectiveContextType>({
  isLoading: false,
  error: null,
  businessObjectives: [],
  addBusinessObjective: () => Promise.resolve(),
  editBusinessObjective: () => Promise.resolve(),
  deleteBusinessObjective: () => Promise.resolve(),
});
const BusinessObjectivesProvider = (props: React.PropsWithChildren) => {
  const [data, setData] = useState<BusinessObjective[]>([]);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatusState>({
    isLoading: false,
    error: null,
  });

  const getBusinessObjectives = useCallback(async () => {
    setLoadingStatus({ error: null, isLoading: true });
    try {
      const newObjectives = await api.getBusinessObjectives();
      setData(newObjectives);
    } catch (error: any) {
      setLoadingStatus({
        error: error instanceof Error ? error : new Error(error?.message ?? error ?? 'Unable to retrieve the data'),
        isLoading: false,
      });
      console.log(error);
      return;
    }
    setLoadingStatus(prev => ({ ...prev, isLoading: false }));
  }, [setLoadingStatus, setData]);

  const addBusinessObjective = useCallback(
    async (title: string, status: string) => {
      setLoadingStatus({ error: null, isLoading: true });
      try {
        await api.addBusinessObjectives(title, status);
        const newObjectives = await api.getBusinessObjectives();
        setData(newObjectives);
      } catch (error: any) {
        setLoadingStatus({
          error: error instanceof Error ? error : new Error(error?.message ?? error ?? 'Unable to retrieve the data'),
          isLoading: false,
        });
        console.log(error);
        return;
      }
      setLoadingStatus(prev => ({ ...prev, isLoading: false }));
    },
    [getBusinessObjectives]
  );
  const editBusinessObjective = useCallback(
    async (title: string, status: string, originalTitle: string) => {
      setLoadingStatus({ error: null, isLoading: true });
      try {
        await api.editBusinessObjectives(title, status, originalTitle);
        const newObjectives = await api.getBusinessObjectives();
        setData(newObjectives);
      } catch (error: any) {
        setLoadingStatus({
          error: error instanceof Error ? error : new Error(error?.message ?? error ?? 'Unable to retrieve the data'),
          isLoading: false,
        });
        console.log(error);
        return;
      }
      setLoadingStatus(prev => ({ ...prev, isLoading: false }));
    },
    [getBusinessObjectives]
  );
  const deleteBusinessObjective = useCallback(
    async (title: string) => {
      setLoadingStatus({ error: null, isLoading: true });
      try {
        await api.deleteBusinessObjective(title);
        const newObjectives = await api.getBusinessObjectives();
        setData(newObjectives);
      } catch (error: any) {
        setLoadingStatus({
          error: error instanceof Error ? error : new Error(error?.message ?? error ?? 'Unable to retrieve the data'),
          isLoading: false,
        });
        console.log(error);
        return;
      }
      setLoadingStatus(prev => ({ ...prev, isLoading: false }));
    },
    [getBusinessObjectives]
  );

  useEffect(() => {
    void getBusinessObjectives();
  }, []);

  return (
    <BusinessObjectivesContext.Provider
      value={{
        businessObjectives: data,
        addBusinessObjective,
        editBusinessObjective,
        deleteBusinessObjective,
        ...loadingStatus,
      }}
    >
      {props.children}
    </BusinessObjectivesContext.Provider>
  );
};
export const useBusinessObjectivesContext = () => {
  const context = useContext(BusinessObjectivesContext);
  if (!context) {
    throw new Error('useBusinessObjectivesContext must be used inside an BusinessObjectivesContext');
  }
  return context;
};

export default BusinessObjectivesProvider;
