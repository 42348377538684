import React from 'react';
import styled from 'styled-components';
import MUIDialog, { DialogProps } from '@material-ui/core/Dialog';
import MUIDialogContent, { DialogContentProps } from '@material-ui/core/DialogContent';

import DialogTitle from './DialogTitle';

const Dialog = styled(MUIDialog)``;

const DialogContent = styled(MUIDialogContent)`
  padding: 20px;
`;

interface Props {
  children?: React.ReactNode;
  className?: string;
  hideSave?: boolean;
  disabled?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  title?: string;
}

const FormDialog = ({
  children,
  className,
  hideSave = false,
  disabled = false,
  onCancel,
  onSave,
  title,
  ...props
}: Props & DialogContentProps & Omit<DialogProps, 'open'>) => (
  <Dialog
    open
    onClose={onCancel}
    maxWidth={false}
    className={className}
    {...props}
  >
    <DialogTitle
      hideSave={hideSave}
      disabled={disabled}
      onSave={onSave}
      onCancel={onCancel}
    >
      {title}
    </DialogTitle>
    <DialogContent>{children}</DialogContent>
  </Dialog>
);

export default FormDialog;
