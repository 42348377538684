import { put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../../../../../state/action-types';
import * as pageActions from './actions';
import indicatorSpecificLoad from './indicator-detail-loaders';

function* loadRecordDetails(action) {
  try {
    const { indicatorShortName, record } = action.payload;
    const details = yield indicatorSpecificLoad(indicatorShortName, record);
    yield put(pageActions.loadRecordDetailsSuccess(details));
  } catch (e) {
    yield put(pageActions.loadRecordDetailsFailure(e));
  }
}

function* root() {
  yield takeEvery(actionTypes.RECORD_DETAILS_LOAD_RECORD_DETAILS_REQUEST, loadRecordDetails);
}

export default root;
