import { useState } from 'react';
import OverrideIndicatorDialog from '../../OverrideIndicatorDialog';
import PreviousScoreDialog from '../../PreviousScoreDialog';
import { OverrideValue } from '../../../hooks/override-value';
import useIndicator, { IndicatorProvider } from '../../../hooks/indicator';
import useConditionalConfirm from '../../../hooks/conditional-confirm';
import OkayCancelDialog from '../../OkayCancelDialog';
import { PreviousScoreProvider } from '../../../hooks/previous-score';
import OverrideDetails from '../OverrideDetails';
import { OverrideDetailsProvider } from '../../../hooks/override-details';
import RecordsTableModule from '../../ReviewRecordsModule';
import { ElementId } from '../../../types/app';
import UnstyledFormDialog from '../../FormDialog';
import OverrideRecords from '../OverrideRecords';
import DialogSection from './DialogSection';
import OldIndicatorDetailsDialog from './old';
import isFeatureEnabled from '../../../utils/feature-flags';
import { isBlackListedIndicator, isTccIndicator } from '../utils';
import LoadingContainer from '../../LoadingContainer';
import styled from 'styled-components';

interface Props {
  isLive?: boolean;
  readonly?: boolean;
  overrideValue: OverrideValue;
  facilityId: string;
  elementId: ElementId;
  elementType?: string;
  year: number;
  quarter: number;
  onSave: any;
  onCancel: () => void;
  onClear: () => void;
}

const FormDialog = styled(UnstyledFormDialog)`
  .MuiDialog-paper {
    height: 100%;
  }
`;

// The manual record override feature is not supported for TCC indicators & blacklisted indicators
const shouldHideManualRecordOverride = (shortName: string) => {
  return isBlackListedIndicator(shortName) || isTccIndicator(shortName);
};

type IndicatorDialogContentProps = Pick<
  Props,
  | 'isLive'
  | 'readonly'
  | 'overrideValue'
  | 'facilityId'
  | 'elementId'
  | 'elementType'
  | 'year'
  | 'quarter'
  | 'onSave'
  | 'onClear'
> & { setIsFormDirty: React.Dispatch<React.SetStateAction<boolean>> };

const IndicatorDialogContent = ({
  isLive,
  readonly,
  overrideValue,
  facilityId,
  elementId,
  elementType,
  year,
  quarter,
  onSave,
  onClear,
  setIsFormDirty,
}: IndicatorDialogContentProps) => {
  const { shortName } = useIndicator();
  return (
    <LoadingContainer loading={!shortName}>
      <DialogSection title="Associated Records">
        <RecordsTableModule
          elementId={elementId}
          quarter={quarter}
          year={year}
          elementUnit={overrideValue.elementUnit}
        />
      </DialogSection>
      {!isLive && (
        <>
          <DialogSection title="Override">
            <OverrideIndicatorDialog
              readonly={readonly}
              overrideValue={overrideValue}
              onSetFormDirty={setIsFormDirty}
              onSave={onSave}
              onClear={onClear}
            />
          </DialogSection>
          <DialogSection title="Score History">
            <PreviousScoreProvider
              indicatorId={overrideValue.indicatorId}
              elementUnit={overrideValue.elementUnit}
              elementId={elementId}
              year={year}
              quarter={quarter}
            >
              <PreviousScoreDialog indicatorName={overrideValue.indicatorName} />
            </PreviousScoreProvider>
          </DialogSection>
        </>
      )}
      {!shouldHideManualRecordOverride(shortName) && (
        <OverrideDetailsProvider
          indicatorId={overrideValue.indicatorId}
          facilityId={facilityId}
          elementUnit={overrideValue.elementUnit}
          elementId={elementId}
          elementType={elementType}
          year={year}
          quarter={quarter}
        >
          <DialogSection title="Find A Record">
            <OverrideDetails />
          </DialogSection>
          <DialogSection title="Added Records">
            <OverrideRecords />
          </DialogSection>
        </OverrideDetailsProvider>
      )}
    </LoadingContainer>
  );
};

const IndicatorDetailsDialog = ({
  isLive = false,
  readonly,
  overrideValue,
  facilityId,
  elementId,
  elementType,
  year,
  quarter,
  onSave,
  onCancel,
  onClear,
}: Props) => {
  const [isDirty, setIsFormDirty] = useState(false);

  const {
    confirming,
    confirm: confirmCancel,
    onConfirm: onConfirmCancel,
    onCancel: onCancelConfirm,
  } = useConditionalConfirm(onCancel, isDirty);
  return (
    <>
      <FormDialog
        title={`${overrideValue.indicatorName} - ${overrideValue.elementUnit}`}
        onCancel={confirmCancel}
        hideSave
        fullWidth
        maxWidth="md"
      >
        <IndicatorProvider indicatorId={overrideValue.indicatorId}>
          <IndicatorDialogContent
            isLive={isLive}
            readonly={readonly}
            overrideValue={overrideValue}
            facilityId={facilityId}
            elementId={elementId}
            elementType={elementType}
            year={year}
            quarter={quarter}
            onSave={onSave}
            onClear={onClear}
            setIsFormDirty={setIsFormDirty}
          />
        </IndicatorProvider>
      </FormDialog>
      {confirming && (
        <OkayCancelDialog
          title="Cancel?"
          okayLabel="Continue"
          onOkay={onConfirmCancel}
          onCancel={onCancelConfirm}
        >
          Are you sure? If you choose to continue, you will lose the data you have entered.
        </OkayCancelDialog>
      )}
    </>
  );
};

export default isFeatureEnabled('liveScorecardOverrides') ? IndicatorDetailsDialog : OldIndicatorDetailsDialog;
