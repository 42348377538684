import * as R from 'ramda';
import { dateToString } from '../../../utils/date-format';
import { Column, SortableColumn } from '../../SortableTable/types';

const workOrderURL = (workOrderId = '') =>
  `https://prod-maximo.corp.brucepower.com/maximo/ui/maximo.jsp?event=loadapp&value=pluswotr&uniqueid=${workOrderId}`;
const workRequestURL = (ticketUId = '') =>
  `https://prod-maximo.corp.brucepower.com/maximo/ui/maximo.jsp?event=loadapp&value=plusca&uniqueid=${ticketUId}`;
const pmsURL = (pmuid = '') =>
  `https://prod-maximo.corp.brucepower.com/maximo/ui/maximo.jsp?event=loadapp&value=pluspm&uniqueid=${pmuid}`;

const URL = (label: string, link: string) => (
  <a
    href={link}
    target="_blank"
    rel="noreferrer"
  >
    {label}
  </a>
);

const column = (label: string, fieldName: string, width?: number): Column => ({
  id: fieldName,
  label,
  valueFn: R.prop(fieldName),
  width,
});

const sortableColumn = (label: string, fieldName: string, width: number, align?: string): SortableColumn => ({
  id: fieldName,
  label,
  valueFn: R.prop(fieldName),
  width,
  sortable: true,
  align,
});

const sortableFormatColumn = (
  label: string,
  fieldName: string,
  format: any,
  width: number,
  align?: string
): SortableColumn => ({
  id: fieldName,
  label,
  render: (record: any) => format(R.prop(fieldName)(record)),
  width,
  sortable: true,
  align,
});

const sortableLinkColumn = (
  label: string,
  fieldName: string,
  width: number,
  linkFormat: (x: string) => string,
  linkValue: string,
  align?: string
): SortableColumn => ({
  id: fieldName,
  label,
  render: (record: any) =>
    R.isNil(R.prop(linkValue)(record))
      ? R.prop(fieldName)(record)
      : URL(R.prop(fieldName)(record), linkFormat(R.prop(linkValue)(record))),
  width,
  sortable: true,
  align,
});

const indicatorSpecifics = {
  'functional-failures': [
    sortableLinkColumn('WR', 'workRequestNumber', 96, workRequestURL, 'ticketuid'),
    sortableColumn('WO', 'workOrderNumber', 119),
    column('Title', 'title'),
    column('Notes', 'applicabilityDescription'),
    sortableColumn('WR Report Date', 'reportDate', 150),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'outstanding-corrective-actions': [
    sortableLinkColumn('CR Number', 'crNumber', 119, workRequestURL, 'ticketuid'),
    sortableColumn('CR Status', 'status', 119),
    column('Subject', 'description'),
    column('Notes', 'applicabilityDescription'),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'online-deficient-maintenance-backlog': [
    sortableLinkColumn('WO Number', 'workOrderNumber', 119, workOrderURL, 'workOrderId'),
    column('WO Type', 'type', 80),
    column('Title', 'title'),
    column('Notes', 'applicabilityDescription'),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'online-corrective-maintenance-backlog': [
    sortableLinkColumn('WO Number', 'workOrderNumber', 119, workOrderURL, 'workOrderId'),
    column('WO Type', 'type', 80),
    column('Title', 'title'),
    column('Notes', 'applicabilityDescription'),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'shutdown-deficient-maintenance-backlog': [
    sortableLinkColumn('WO Number', 'workOrderNumber', 119, workOrderURL, 'workOrderId'),
    column('WO Type', 'type', 80),
    column('Title', 'title'),
    column('Notes', 'applicabilityDescription'),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'shutdown-corrective-maintenance-backlog': [
    sortableLinkColumn('WO Number', 'workOrderNumber', 119, workOrderURL, 'workOrderId'),
    column('WO Type', 'type', 80),
    column('Title', 'title'),
    column('Notes', 'applicabilityDescription'),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  predefines: [
    sortableLinkColumn('PM Number', 'pmNumber', 119, pmsURL, 'pmuid'),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'regdoc-reportable-events': [
    sortableLinkColumn('CR Number', 'crNumber', 119, workRequestURL, 'ticketuid'),
    sortableColumn('AR Status', 'status', 119),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'operator-workarounds': [
    sortableLinkColumn('WO Number', 'workOrderNumber', 119, workOrderURL, 'workOrderId'),
    column('Title', 'title'),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'operator-burdens': [
    sortableLinkColumn('WO Number', 'workOrderNumber', 119, workOrderURL, 'workOrderId'),
    column('Title', 'title'),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'tmods-beyond-90-days': [
    sortableColumn('TCC ID', 'serialNumber', 119),
    column('Title', 'title'),
    sortableColumn('RSE', 'rse', 72),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'tmods-beyond-6-months': [
    sortableColumn('TCC ID', 'serialNumber', 119),
    column('Title', 'title'),
    sortableColumn('RSE', 'rse', 72),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'modification-backlog': [
    sortableLinkColumn('WO Number', 'workOrderNumber', 119, workOrderURL, 'workOrderId'),
    column('Title', 'title'),
    column('Notes', 'applicabilityDescription'),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'toe-corrective-actions': [
    sortableLinkColumn('CR Number', 'crNumber', 119, workRequestURL, 'ticketuid'),
    sortableColumn('CR Status', 'status', 119),
    column('Subject', 'description'),
    column('Notes', 'applicabilityDescription'),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'comp-functional-failures': [
    sortableLinkColumn('WR', 'workRequestNumber', 96, workRequestURL, 'ticketuid'),
    sortableLinkColumn('WO', 'workOrderNumber', 119, workOrderURL, 'workOrderId'),
    column('Title', 'title'),
    column('Notes', 'applicabilityDescription'),
    sortableColumn('WR Report Date', 'reportDate', 150),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'comp-outstanding-corrective-actions': [
    sortableLinkColumn('CR Number', 'crNumber', 119, workRequestURL, 'ticketuid'),
    sortableColumn('CR Status', 'status', 119),
    column('Subject', 'description'),
    column('Notes', 'applicabilityDescription'),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'comp-er-clock-resets': [
    sortableLinkColumn('CR Number', 'crNumber', 119, workRequestURL, 'ticketuid'),
    sortableColumn('CR Status', 'status', 119),
    column('Subject', 'description'),
    column('Notes', 'applicabilityDescription'),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'comp-equipment-defects': [
    sortableLinkColumn('WR', 'workRequestNumber', 96, workRequestURL, 'ticketuid'),
    sortableColumn('WO', 'workOrderNumber', 119),
    column('Title', 'title'),
    column('Notes', 'applicabilityDescription'),
    sortableColumn('WR Report Date', 'reportDate', 150),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'comp-predefines': [
    sortableLinkColumn('PM Number', 'pmNumber', 119, pmsURL, 'pmuid'),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'comp-component-health-effectiveness': [
    sortableColumn('Action ID', 'actionId', 119),
    sortableFormatColumn('Target Date', 'targetDate', dateToString, 82),
    sortableColumn('State', 'state', 82),
  ],
  'comp-online-deficient-maintenance-backlog': [
    sortableLinkColumn('WO Number', 'workOrderNumber', 119, workOrderURL, 'workOrderId'),
    column('WO Type', 'type', 80),
    column('Title', 'title'),
    column('Notes', 'applicabilityDescription'),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'comp-online-corrective-maintenance-backlog': [
    sortableLinkColumn('WO Number', 'workOrderNumber', 119, workOrderURL, 'workOrderId'),
    column('WO Type', 'type', 80),
    column('Title', 'title'),
    column('Notes', 'applicabilityDescription'),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'comp-shutdown-deficient-maintenance-backlog': [
    sortableLinkColumn('WO Number', 'workOrderNumber', 119, workOrderURL, 'workOrderId'),
    column('WO Type', 'type', 80),
    column('Title', 'title'),
    column('Notes', 'applicabilityDescription'),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'comp-shutdown-corrective-maintenance-backlog': [
    sortableLinkColumn('WO Number', 'workOrderNumber', 119, workOrderURL, 'workOrderId'),
    column('WO Type', 'type', 80),
    column('Title', 'title'),
    column('Notes', 'applicabilityDescription'),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
  'comp-maintenance-feedback-backlog': [
    sortableLinkColumn('WO Number', 'workOrderNumber', 119, workOrderURL, 'workOrderId'),
    column('Title', 'title'),
    column('Notes', 'applicabilityDescription'),
    sortableColumn('USI', 'usi', 72),
    sortableColumn('Unit', 'elementUnit', 81),
  ],
};

const useIndicatorRecordColumns = (indicatorShortName: string): Array<SortableColumn> =>
  R.propOr([], indicatorShortName, indicatorSpecifics);

export default useIndicatorRecordColumns;
