import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { ActionsContextProvider } from '../hooks/useActions';
import AddNewWorkorderPage from './pages/AddNewWorkorderPage';
import DropdownMaintenancePage from './pages/DropdownMaintenancePage';
import { WorkOrderFlagsContextProvider } from '../hooks/useWorkOrderFlags';

const AdminPage = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/add-new-workorder`}>
        <AddNewWorkorderPage />
      </Route>
      <Route path={`${path}/dropdown-maintenance`}>
        <ActionsContextProvider>
          <WorkOrderFlagsContextProvider>
            <DropdownMaintenancePage />
          </WorkOrderFlagsContextProvider>
        </ActionsContextProvider>
      </Route>
      <Route
        exact
        path={path}
      >
        <Redirect to={`${path}/add-new-workorder`} />
      </Route>
    </Switch>
  );
};
export default AdminPage;
