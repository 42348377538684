import React, { useState } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import MUITypography from '@material-ui/core/Typography';
import IndicatorRecordTable from '../index';
import useIndicator from '../../../hooks/indicator';
import Conditional from '../../Conditional';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Collapse } from '@material-ui/core';
import UnstyledButton from '@material-ui/core/Button';
import UnstyledList from '@material-ui/core/List';
import { IndicatorRecord } from '../../../api/indicators/types';
import { isBlackListedIndicator as checkIfBlackListedIndicator } from '../../Scorecard/utils';

const Container = styled.div``;
const Button = styled(UnstyledButton)`
  size: 'small';
`;

const Typography = styled(MUITypography)`
  font: ${({ theme }) => theme.fonts.bold14};
`;

const TableContainer = styled.div`
  padding-bottom: 20px;
  ${Typography} {
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

const NotEvaluatedTableContainer = styled(TableContainer)`
  background-color: ${({ theme }) => theme.colours.greys.lightGrey8};
`;

const EvaluatedTableContainer = styled(TableContainer)``;

const List = styled(UnstyledList)`
  &:nth-child(odd) {
    background-color: #f5f5f5;
  }
`;

interface Props {
  className?: string;
  records: Array<IndicatorRecord>;
  readonly?: boolean;
  onApply?: any;
  onDismiss?: any;
  onApplicable?: any;
  noPromptApply?: any;
  isLoading: boolean;
}

const RecordTables = ({
  className,
  records,
  readonly = false,
  onApply,
  onDismiss,
  onApplicable,
  noPromptApply,
  isLoading,
}: Props) => {
  const { id: indicatorId, shortName: indicatorShortName } = useIndicator();
  const groupedRecords = R.groupBy(record => {
    if (R.isNil(record.applicable)) {
      return 'notEvaluated';
    }
    return record.applicable ? 'applicable' : 'notApplicable';
  }, records);
  const notEvaluatedRecords = R.uniq(
    R.propOr<IndicatorRecord[], typeof groupedRecords, IndicatorRecord[]>([], 'notEvaluated', groupedRecords)
  );
  const appliedRecords = R.uniq(
    R.propOr<IndicatorRecord[], typeof groupedRecords, IndicatorRecord[]>([], 'applicable', groupedRecords)
  );
  const notAppliedRecords = R.uniq(
    R.propOr<IndicatorRecord[], typeof groupedRecords, IndicatorRecord[]>([], 'notApplicable', groupedRecords)
  );

  const isBlackListedIndicator = checkIfBlackListedIndicator(indicatorShortName);

  const [minimizedApplied, setMinimizedApplied] = useState(false);
  const [minimizedNotApplied, setMinimizedNotApplied] = useState(false);

  const toggleMinimizeApplied = () => {
    setMinimizedApplied(!minimizedApplied);
  };

  const toggleMinimizeNotApplied = () => {
    setMinimizedNotApplied(!minimizedNotApplied);
  };

  return (
    <Container className={className}>
      <Conditional condition={!isLoading}>
        {isBlackListedIndicator && !R.isEmpty(records) && (
          <EvaluatedTableContainer>
            <IndicatorRecordTable
              indicatorId={indicatorId}
              indicatorShortName={indicatorShortName}
              items={records}
              evaluated={true}
              readonly={true}
            />
          </EvaluatedTableContainer>
        )}
        {!isBlackListedIndicator && !R.isEmpty(notEvaluatedRecords) && (
          <NotEvaluatedTableContainer>
            <Typography>{`Not Evaluated Records (${notEvaluatedRecords.length})`}</Typography>
            <IndicatorRecordTable
              indicatorId={indicatorId}
              indicatorShortName={indicatorShortName}
              items={notEvaluatedRecords}
              evaluated={false}
              onApply={onApply}
              onDismiss={onDismiss}
              readonly={readonly}
            />
          </NotEvaluatedTableContainer>
        )}
        {!isBlackListedIndicator && !R.isEmpty(appliedRecords) && (
          <List>
            <EvaluatedTableContainer>
              <Typography>
                <Button onClick={toggleMinimizeApplied}>
                  {minimizedApplied ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Button>
                {`Applied Records (${appliedRecords.length})`}
              </Typography>
              <Collapse
                in={minimizedApplied}
                timeout="auto"
                unmountOnExit
              >
                <IndicatorRecordTable
                  indicatorId={indicatorId}
                  indicatorShortName={indicatorShortName}
                  items={appliedRecords}
                  evaluated={false}
                  onApply={null}
                  onDismiss={onDismiss}
                  onApplicable={onApplicable}
                  noPromptApply={noPromptApply}
                  readonly={readonly}
                />
              </Collapse>
            </EvaluatedTableContainer>
          </List>
        )}
        {!isBlackListedIndicator && !R.isEmpty(notAppliedRecords) && (
          <EvaluatedTableContainer>
            <Typography>
              <Button onClick={toggleMinimizeNotApplied}>
                {minimizedNotApplied ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Button>
              {`Not Applied Records (${notAppliedRecords.length})`}
            </Typography>
            <Collapse
              in={minimizedNotApplied}
              timeout="auto"
              unmountOnExit
            >
              <IndicatorRecordTable
                indicatorId={indicatorId}
                indicatorShortName={indicatorShortName}
                items={notAppliedRecords}
                evaluated={false}
                onApply={onApply}
                onDismiss={null}
                onApplicable={onApplicable}
                noPromptApply={noPromptApply}
                readonly={readonly}
              />
            </Collapse>
          </EvaluatedTableContainer>
        )}
        {R.isEmpty(notEvaluatedRecords) && R.isEmpty(appliedRecords) && R.isEmpty(notAppliedRecords) && (
          <p>
            <em>No records</em>
          </p>
        )}
      </Conditional>
    </Container>
  );
};

export default RecordTables;
