import React, { useState } from 'react';
import styled from 'styled-components';
import MUITextField from '@material-ui/core/TextField';
import UnstyledFormDialog from '../../../../components/FormDialog';
import OkayCancelDialog from '../../../../components/OkayCancelDialog';
import useConditionalConfirm from '../../../../hooks/conditional-confirm';

const TextField = styled(MUITextField).attrs({
  fullWidth: true,
})``;

const FormDialog = styled(UnstyledFormDialog)`
  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    width: 40vw;
    min-width: 400px;
  }

  ${TextField} {
    margin-bottom: 20px;
  }
`;
interface Props {
  className: string;
  title: string;
  onClose: any;
  onEdit: any;
}

const AdminCategoriesDialog = ({ className, title, onClose, onEdit }: Props) => {
  const [comment, setComment] = useState('');

  const {
    confirming,
    confirm: confirmCancel,
    onConfirm: onConfirmCancel,
    onCancel: onCancelConfirm,
  } = useConditionalConfirm(onClose, true);

  const handleSave = async (categoryName: any) => {
    await onEdit(categoryName);
    onClose();
  };
  const handleTextInputChange = (event: any) => {
    setComment(event.target.value);
  };

  return (
    <FormDialog
      className={className}
      title={title}
      onCancel={confirmCancel}
      onSave={() => handleSave(comment)}
    >
      <TextField
        label="Category Name"
        onChange={handleTextInputChange}
        value={comment}
      />
      {confirming && (
        <OkayCancelDialog
          title="Lose changes?"
          okayLabel="Continue"
          onOkay={onConfirmCancel}
          onCancel={onCancelConfirm}
        >
          You have unsaved changes. If you continue, you will lose them.
        </OkayCancelDialog>
      )}
    </FormDialog>
  );
};

export default AdminCategoriesDialog;
