export const ACCEPT_AND_KEEP_ACTIVE = 'Accept and Keep Active';
export const ACCEPT_AND_CLOSE = 'Accept and Close';
export const REJECT_AND_CLOSE = 'Reject and Close';
export const REJECT_AND_RESUBMIT = 'Reject and Resubmit';
export const EDITED_BY_APPROVER = 'Edited by Approver';

export const SECTION_MANAGER = 'Section Manager';
export const DEPARTMENT_MANAGER = 'Department Manager';
export const DIVISION_MANAGER = 'Division Manager';
export const SVP = 'Senior Vice President';
export const CNO = 'Chief Nuclear Officer';

export enum RISK_STATES {
  SUBMITTED = 'SUBMITTED',
  DRAFT = 'DRAFT',
  ENV_REQUIRED = 'ENV_REQUIRED',
  CLOSED = 'CLOSED',
  APPROVED = 'APPROVED',
}

export enum ESCALATION_OPTIONS {
  ESCALATE_RISK = 'yes',
  NO_ESCALATION = 'no',
}
