import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { WorkorderFlag } from '../../../types/cwt/workorder';
import isFeatureEnabled from '../../../utils/feature-flags';
import Api from '../../../api';

interface UseWorkOrderFlagsValue {
  workorderFlags: WorkorderFlag[];
  setNewWorkflowFlag: (label: string) => void;
  newWorkflowFlag: string;
  addWorkorderFlag: (label: string) => Promise<void>;
  removeWorkorderFlag: (id: number) => Promise<void>;
}

interface Props {
  children: ReactNode;
}

const WorkOrderFlagsContext = createContext<UseWorkOrderFlagsValue | undefined>(undefined);

export const WorkOrderFlagsContextProvider = ({ children }: Props) => {
  const [newWorkflowFlag, setNewWorkflowFlag] = useState('');
  const [workorderFlags, setWorkorderFlags] = useState<WorkorderFlag[]>([]);
  const [loadingWorkorderFlags, setLoadingWorkorderFlags] = useState<boolean>(true);

  useEffect(() => {
    const getWorkOrderFlags = async () => {
      await getAllWorkorderFlags();
      setLoadingWorkorderFlags(false);
    };
    if (loadingWorkorderFlags) {
      void getWorkOrderFlags();
    }
  }, [loadingWorkorderFlags]);

  const getAllWorkorderFlags = async () => {
    if (isFeatureEnabled('workorderFlags')) {
      const res = await Api.cwt.getAllWorkorderFlags();
      setWorkorderFlags(res);
    }
  };

  const addWorkorderFlag = async (label: string) => {
    if (isFeatureEnabled('workorderFlags')) {
      await Api.cwt.addWorkorderFlag(label);
      setNewWorkflowFlag('');
      setLoadingWorkorderFlags(true);
    }
  };

  const removeWorkorderFlag = async (id: number) => {
    if (isFeatureEnabled('workorderFlags')) {
      await Api.cwt.disableWorkorderFlag(id);
      setLoadingWorkorderFlags(true);
    }
  };

  return (
    <WorkOrderFlagsContext.Provider
      value={{ workorderFlags, newWorkflowFlag, setNewWorkflowFlag, addWorkorderFlag, removeWorkorderFlag }}
    >
      {children}
    </WorkOrderFlagsContext.Provider>
  );
};

export const useWorkOrderFlags = () => {
  const context = useContext(WorkOrderFlagsContext);
  if (context === undefined) {
    throw new Error('useWorkOrderFlags must be used within a WorkOrdersFlagsContextProvider');
  }
  return context;
};
