import React, { useCallback, useState } from 'react';

import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Button, Typography } from '@material-ui/core';
import { OutagePlan } from '../../../../../../types/outage-plans';
import { useOutagePlans } from '../../../../../../context/outage-plans';
import ActionButton from '../../../../../../components/ActionButton';
import NewOutagePlanDashboard from './NewOutagePlanDashboard';
import EditOutagePlanDashboard from './EditOutagePlan';
import { useDispatch } from 'react-redux';
import * as uiActions from '../../../../../../state/ui/actions';

interface RowProps {
  item: OutagePlan;
  onEdit: (outagePlan: OutagePlan) => void;
  onDelete: (outagePlan: OutagePlan) => void;
}

const Row = ({ item, onEdit, onDelete }: RowProps) => (
  <TableRow>
    <TableCell>{item.outage_code}</TableCell>
    <TableCell>{item.status}</TableCell>
    <TableCell>
      <Button onClick={() => onEdit(item)}>EDIT</Button>
    </TableCell>
    <TableCell>
      <Button onClick={() => onDelete(item)}>DELETE</Button>
    </TableCell>
  </TableRow>
);

const OutagePlanTable = () => {
  const { outagePlans, deleteOutagePlan, error } = useOutagePlans();
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [originalOutageCode, setOriginalOutageCode] = useState('');
  const [originalStatus, setOriginalStatus] = useState('');
  const dispatch = useDispatch();

  const handleDelete = useCallback(
    async (item: OutagePlan) => {
      dispatch(uiActions.notesMessage('Deleting...'));
      try {
        await deleteOutagePlan(item.outage_code);
        dispatch(uiActions.genericMessage('Deleted Succesfully'));
      } catch {
        dispatch(uiActions.error('', error ?? 'Delete Unsuccesful'));
      }
    },
    [deleteOutagePlan, error, dispatch]
  );

  return (
    <>
      <NewOutagePlanDashboard
        opened={openAddDialog}
        setOpened={setOpenAddDialog}
      />
      <EditOutagePlanDashboard
        opened={openEditDialog}
        setOpened={setOpenEditDialog}
        originalOutageCode={originalOutageCode}
        originalStatus={originalStatus}
      />
      <TableContainer>
        {outagePlans.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Outage Code</TableCell>
                <TableCell>Status</TableCell>
                <TableCell />
                <TableCell>
                  <ActionButton
                    color="primary"
                    onClick={() => setOpenAddDialog(true)}
                  >
                    Add New Outage Plan
                  </ActionButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {outagePlans
                .filter(item => !item.is_deleted)
                .map((item: OutagePlan) => (
                  <Row
                    key={item.outage_code}
                    item={item}
                    onEdit={item => {
                      setOpenEditDialog(true);
                      setOriginalOutageCode(item.outage_code);
                      setOriginalStatus(item.status);
                    }}
                    onDelete={async item => await handleDelete(item)}
                  />
                ))}
            </TableBody>
          </Table>
        ) : (
          <Typography> No Outage Plans Exist Yet</Typography>
        )}
      </TableContainer>
    </>
  );
};

export default OutagePlanTable;
