import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import CarlaText from '../../../../../../components/CarlaText';
import DialogButton from '../../../../../../components/DialogButton';
import CustomTextField from '../../../../../../components/CustomTextField';
import Select from '../../../../../../components/Select';
import { useCallback, useEffect, useState } from 'react';
import * as uiActions from '../../../../../../state/ui/actions';
import { useDispatch } from 'react-redux';
import { type BusinessObjective } from '../../../../../../types/business-objectives';
import { useBusinessObjectivesContext } from '../../../../../../context/business-objective';
import LoadingContainer from '../../../../../../components/LoadingContainer';

type Props = {
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  selected: BusinessObjective;
};

const EditBusinessObjectiveDashboard = ({ setOpened, selected: { title: originalTitle, status } }: Props) => {
  const { editBusinessObjective, isLoading } = useBusinessObjectivesContext();
  const [title, setTitle] = useState(originalTitle);
  const [selectedStatus, setSelectedStatus] = useState(status);
  const dispatch = useDispatch();
  useEffect(() => {
    setTitle(originalTitle);
  }, [originalTitle]);

  const handleEdit = useCallback(async () => {
    if (title && selectedStatus) {
      try {
        await editBusinessObjective(title, selectedStatus, originalTitle);
        dispatch(uiActions.genericMessage('Updated Succesfully'));
        setOpened(false);
      } catch (err: any) {
        dispatch(uiActions.error('', err?.message ?? err ?? 'Failed to edit Business Objective'));
      }
    } else {
      dispatch(uiActions.error('', 'Please enter a valid input'));
    }
  }, [title, selectedStatus]);

  return (
    <Dialog
      open
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Edit Business Objective</DialogTitle>
      <DialogContent>
        {isLoading && <LoadingContainer loading />}
        {!isLoading && (
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <CarlaText>Edit title and set status</CarlaText>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <CustomTextField
                label="Title"
                inputProps={{ maxLength: 256 }}
                onChange={e => setTitle(e.target.value)}
                defaultValue={originalTitle}
              ></CustomTextField>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Select
                fullWidth
                label="Status"
                options={[
                  { label: 'Active', value: 'Active' },
                  { label: 'In-Active', value: 'In-Active' },
                ]}
                onChange={e => setSelectedStatus(e.target.value)}
                value={selectedStatus}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      {!isLoading && (
        <DialogActions>
          <DialogButton onClick={() => setOpened(false)}>Cancel</DialogButton>
          <DialogButton
            color="primary"
            onClick={handleEdit}
          >
            Update
          </DialogButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default EditBusinessObjectiveDashboard;
