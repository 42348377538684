import { IndicatorRecord } from '../../api/indicators/types';
import { OverrideRecordDetails } from './types';

/**
 * The "comp-component-health-effectiveness" indicator is invalid and should not be applied/unapplied
 */
export const isBlackListedIndicator = (indicatorShortName: string) =>
  indicatorShortName === 'comp-component-health-effectiveness';

/**
 * Manual override records cannot be added for TCC indicators (until we migrate to using new TCC data from the PLUSCA table)
 */
export const isTccIndicator = (indicatorShortName: string) =>
  ['tmods-beyond-6-months', 'tmods-beyond-90-days'].includes(indicatorShortName);

export const buildLocationFromParts = (unitNumber: string, usi: string, equipmentTag: string | null) => {
  // Unit number and USI are required, so location is invalid if either is empty
  if (!unitNumber || !usi) {
    return null;
  }
  return `${unitNumber}-${usi}${equipmentTag ? `-${equipmentTag}` : ''}`;
};

export const extractRecordDetails = (record: IndicatorRecord): OverrideRecordDetails | null => {
  if ('crNumber' in record) {
    return {
      title: `CR ${record.crNumber}`,
      description: record.description,
      status: record.status,
      location: record.location ?? buildLocationFromParts(record.unitNumber, record.usi, record.equipmentTag),
    };
  } else if ('workRequestNumber' in record) {
    return {
      title: `WR ${record.workRequestNumber}`,
      description: record.title,
      status: record.status,
      location: record.location ?? buildLocationFromParts(record.unitNumber, record.usi, record.equipmentTag),
    };
  } else if ('pmNumber' in record) {
    return {
      title: `PM ${record.pmNumber}`,
      description: record.description,
      // No status for PM views
      status: null,
      location: record.location ?? buildLocationFromParts(record.unitNumber, record.usi, record.equipmentTag),
    };
  } else if ('workOrderNumber' in record) {
    return {
      title: `WO ${record.workOrderNumber}`,
      description: record.title,
      status: record.status,
      location: record.location ?? buildLocationFromParts(record.unitNumber, record.usi, record.equipmentTag),
    };
  } else if ('tccId' in record) {
    return {
      title: `TCC ${record.tccId}`,
      description: record.title,
      status: record.status,
      location: record.location ?? buildLocationFromParts(record.unitNumber, record.usi, record.equipmentTag),
    };
  }
  return null;
};
