import {
  ACCEPT_AND_KEEP_ACTIVE,
  ACCEPT_AND_CLOSE,
  REJECT_AND_RESUBMIT,
  REJECT_AND_CLOSE,
  EDITED_BY_APPROVER,
  SECTION_MANAGER,
  DEPARTMENT_MANAGER,
  DIVISION_MANAGER,
  SVP,
  CNO,
} from '../../constants';
import {
  DecisionTypeRiskApprover,
  RiskEscalationRoles,
  RiskApproverFormInputs,
  RiskEscalationTableFields,
} from '../../../../types/risk-management';

const ESCALATION_ORDER: RiskEscalationRoles[] = [
  'sectionManager',
  'departmentManager',
  'divisionManager',
  'svp',
  'cno',
];

export const getDecisionOption = (option: DecisionTypeRiskApprover) => {
  switch (option) {
    case 'acceptAndMonitor':
      return ACCEPT_AND_KEEP_ACTIVE;
    case 'acceptAndClose':
      return ACCEPT_AND_CLOSE;
    case 'rejectAndResubmit':
      return REJECT_AND_RESUBMIT;
    case 'rejectAndClose':
      return REJECT_AND_CLOSE;
    case 'editByApprover':
      return EDITED_BY_APPROVER;
    default:
      return 'N/A';
  }
};

export const getEscalationRoleLabel = (option: RiskEscalationRoles) => {
  switch (option) {
    case 'sectionManager':
      return SECTION_MANAGER;
    case 'departmentManager':
      return DEPARTMENT_MANAGER;
    case 'divisionManager':
      return DIVISION_MANAGER;
    case 'svp':
      return SVP;
    case 'cno':
      return CNO;
    default:
      return 'Unknown Role';
  }
};

interface BuildApproverSubmissionProps {
  data: Omit<RiskApproverFormInputs, 'escalateUsers'>;
  newApproverForm: boolean;
  hasBeenEscalated: boolean;
}

export const buildApproverSubmission = ({ data, newApproverForm, hasBeenEscalated }: BuildApproverSubmissionProps) => {
  return {
    escalateIssue: data.escalateIssue,
    escalateIssueRationale: data.escalateIssueRationale,
    escalateTo: '',
    decisionType: data.decisionType,
    decisionTypeDescription: data.decisionType === 'acceptAndMonitor' ? null : data.decisionTypeDescription,
    flagToCno: null,
    cnoBridgingStrategy: hasBeenEscalated ? data.cnoBridgingStrategy : '',
    cnoDescription: hasBeenEscalated ? data.cnoDescription : '',
    cnoStatusUpdate: hasBeenEscalated ? data.cnoStatusUpdate : '',
    riskResponseSummary: hasBeenEscalated ? data.riskResponseSummary : '',
    newApproverForm,
  };
};

interface BuildEscalationProps {
  data: Omit<RiskApproverFormInputs, 'escalateUsers'>;
  escalateToRole: RiskEscalationRoles;
  riskManagementId: number;
  escalationWorkflowStep: number;
}

export const buildEscalationSubmission = ({
  data,
  escalateToRole,
  riskManagementId,
  escalationWorkflowStep,
}: BuildEscalationProps): Omit<RiskEscalationTableFields, 'createdAt' | 'createdBy'> => {
  return {
    escalationWorkflowStep,
    riskManagementId,
    escalationRationale: data.escalateIssueRationale,
    escalateTo: data.escalateTo,
    escalateToRole,
    recommendedDecisionType: data.recommendedDecisionType,
    recommendedDecisionTypeDescription: data.recommendedDecisionTypeDescription,
    riskBridgingStrategy: data.cnoBridgingStrategy,
    riskDescription: data.cnoDescription,
    riskStatusUpdate: data.cnoStatusUpdate,
    riskResponseSummary: data.riskResponseSummary,
  };
};

export const getEscalationNeighbours = (targetRole: RiskEscalationRoles) => {
  const index = ESCALATION_ORDER.indexOf(targetRole);

  if (index === -1) {
    return { before: null, after: null };
  }

  const before = index > 0 ? ESCALATION_ORDER[index - 1] : null;
  const after = index < ESCALATION_ORDER.length - 1 ? ESCALATION_ORDER[index + 1] : null;

  return { before, after };
};
