import { useMemo, useState } from 'react';
import Page from '../../../../components/Page';
import { Button, Paper } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import RiskFormTabGroup, { RiskFormTab } from '../../components/RiskFormTabGroup';
import { useHistory, useLocation } from 'react-router-dom';
import useRiskManagement from '../../../../context/risk-management';
import isFeatureEnabled from '../../../../utils/feature-flags';

const NewRisk = () => {
  const [currentTab, setCurrentTab] = useState<RiskFormTab>('owner');
  const { push } = useHistory();
  const { data, riskOwnerForm } = useRiskManagement();
  const { pathname } = useLocation();

  const showEnvironmentGroup = useMemo(() => {
    return (
      data.status === 'ENV_REQUIRED' ||
      ((data.status === 'CLOSED' ||
        data.status === 'SUBMITTED' ||
        data.status === 'APPROVED' ||
        data.status === 'REJECTED') &&
        riskOwnerForm?.watchRiskCategory.environmentalSafety)
    );
  }, [data.status, riskOwnerForm?.watchRiskCategory.environmentalSafety]);

  const handleBackClick = () => {
    if (riskOwnerForm && data.status === 'DRAFT' && !!riskOwnerForm.watchElementId) {
      riskOwnerForm.saveDraft('back');
    } else if (
      isFeatureEnabled('riskAllowRiskApproverEditDetails') &&
      riskOwnerForm &&
      data.status === 'SUBMITTED' &&
      Object.keys(riskOwnerForm.dirtyFields).length !== 0
    ) {
      riskOwnerForm.setApproverDiscarding({ discarding: true, source: 'back' });
      return;
    }
    riskOwnerForm?.setApproverEditing(false);
    pathname.includes('/review')
      ? push('/app/business-equipment-risks/risk-review')
      : push('/app/business-equipment-risks');
  };

  const backDisabled = !!riskOwnerForm && riskOwnerForm?.submitting;

  return (
    <Page
      title="Business / Equipment Risk"
      bodyComponent={<Paper square />}
    >
      <Button
        data-testid="btnBack"
        color="primary"
        onClick={handleBackClick}
        disabled={backDisabled}
      >
        <ArrowBackIosIcon color={backDisabled ? 'disabled' : 'primary'} />
        Back
      </Button>

      <RiskFormTabGroup
        value={currentTab}
        onChange={(_, newValue) => setCurrentTab(newValue)}
        tabs={[
          'owner',
          ...(data.status === 'SUBMITTED' ||
          data.status === 'CLOSED' ||
          data.status === 'APPROVED' ||
          data.status === 'REJECTED'
            ? (['approver'] as RiskFormTab[])
            : []),
          ...(showEnvironmentGroup ? (['environmentalGroup'] as RiskFormTab[]) : []),
        ]}
      />
    </Page>
  );
};
export default NewRisk;
