import React, { createContext, useContext, useEffect, useState } from 'react';
import api from '../api';
import { ElementId } from '../types/app';

type PreviousScoreContext = {
  previousScoreData: {
    score: number;
    colour: string;
    year: number;
    quarter: string;
  } | null;
  loading: boolean;
};

// Context
const PreviousScoreContext = createContext<PreviousScoreContext | null>(null);

// Hook
const usePreviousScore = () => {
  const data = useContext(PreviousScoreContext);
  if (!data) {
    throw new Error('usePreviousScore must be used inside an PreviousScoreProvider');
  }
  return data;
};

export default usePreviousScore;

type PreviousScoreProviderProps = React.PropsWithChildren<{
  indicatorId: number;
  elementUnit: string;
  elementId: ElementId;
  year: number;
  quarter: number;
}>;

// Provider
export const PreviousScoreProvider = ({
  children,
  indicatorId,
  elementUnit,
  elementId,
  year,
  quarter,
}: PreviousScoreProviderProps) => {
  const [previousScoreData, setPreviousScoreData] = useState(null);
  const [loading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    api
      .getIndicatorScore(indicatorId, elementUnit, elementId, year, quarter)
      .then((response: any) => {
        setPreviousScoreData(response);
        setIsLoading(false);
      })
      .catch(() => {
        // Make sure to disable loading spinner if request fails
        setIsLoading(false);
      });
  }, [indicatorId, elementId]);
  return (
    <PreviousScoreContext.Provider value={{ previousScoreData, loading }}>{children}</PreviousScoreContext.Provider>
  );
};
