import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Button, Typography } from '@material-ui/core';
import UnstyledCircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { BusinessObjective } from '../../../../../../types/business-objectives';
import { useBusinessObjectivesContext } from '../../../../../../context/business-objective';
import NewBusinessObjectiveDashboard from './NewBusinessObjectiveDashboard';
import EditBusinessObjectiveDashboard from './EditBusinessObjectiveDashboard';
import ActionButton from '../../../../../../components/ActionButton';
import * as uiActions from '../../../../../../state/ui/actions';
import { useDispatch } from 'react-redux';
import LoadingContainer from '../../../../../../components/LoadingContainer';

const CircularProgress = styled(UnstyledCircularProgress)``;
interface RowProps {
  item: BusinessObjective;
  isDeleteInProgress: boolean;
  onEdit: (businessObjective: BusinessObjective) => void;
  onDelete: (businessObjective: BusinessObjective) => void;
}

const Row = ({ item, isDeleteInProgress, onEdit, onDelete }: RowProps) => (
  <TableRow>
    <TableCell>{item.title}</TableCell>
    <TableCell>{item.status}</TableCell>
    <TableCell>
      <Button onClick={() => onEdit(item)}>EDIT</Button>
    </TableCell>
    <TableCell>
      <Button
        onClick={() => {
          onDelete(item);
        }}
      >
        DELETE
      </Button>
      {isDeleteInProgress && <CircularProgress size={14} />}
    </TableCell>
  </TableRow>
);

const BusinessObjectivesTable = () => {
  const { businessObjectives, deleteBusinessObjective, isLoading } = useBusinessObjectivesContext();
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [deletesInProgress, setDeletesInProgress] = useState<string[]>([]);
  const [selected, setSelected] = useState<BusinessObjective | null>(null);
  const dispatch = useDispatch();

  const handleDelete = useCallback(
    async (item: BusinessObjective) => {
      setDeletesInProgress(prev => [...prev, item.title]);
      try {
        await deleteBusinessObjective(item.title);
        dispatch(uiActions.genericMessage('deleted succesfully'));
      } catch (error) {
        console.error('There was error trying to delete a business objective\n', error);
        dispatch(uiActions.error('', 'delete unsuccesful'));
      }
      setDeletesInProgress(prev => {
        const pos = prev.findIndex(r => r === item.title);
        if (pos < 0) {
          return prev;
        }
        // mutates original and returns array of removed
        prev.splice(pos, 1);
        return [...prev];
      });
    },
    [deleteBusinessObjective, dispatch, setDeletesInProgress]
  );

  if (businessObjectives.length === 0 && isLoading) {
    return <LoadingContainer loading />;
  }

  return (
    <>
      {openAddDialog && (
        <NewBusinessObjectiveDashboard
          opened={openAddDialog}
          setOpened={setOpenAddDialog}
        />
      )}
      {openEditDialog && selected && (
        <EditBusinessObjectiveDashboard
          setOpened={setOpenEditDialog}
          selected={selected}
        />
      )}
      <TableContainer>
        {businessObjectives.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Status</TableCell>
                <TableCell />
                <TableCell>
                  <ActionButton
                    color="primary"
                    onClick={() => setOpenAddDialog(true)}
                  >
                    Add New Business Objective
                  </ActionButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {businessObjectives
                .filter(item => !item.is_deleted)
                .map((item: BusinessObjective) => (
                  <Row
                    key={`business-objective-${window.btoa(item.title)}`}
                    item={item}
                    isDeleteInProgress={!!deletesInProgress.includes(item.title)}
                    onEdit={() => {
                      setOpenEditDialog(true);
                      setSelected(item);
                    }}
                    onDelete={handleDelete}
                  />
                ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>No Business Objectives Exist Yet</Typography>
        )}
      </TableContainer>
    </>
  );
};

export default BusinessObjectivesTable;
