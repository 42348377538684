import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import useOverrideDetails from '../../hooks/override-details';
import OverrideRecordCard from './OverrideRecordCard';
import { ManualAssociatedRecord } from '../../api/elements/types';
import theme from '../../theme';

const getTitle = ({ crNumber, pmNumber, workOrderNumber, workRequestNumber }: ManualAssociatedRecord) => {
  if (crNumber) {
    return `CR ${crNumber}`;
  }
  if (pmNumber) {
    return `PM ${pmNumber}`;
  }
  if (workRequestNumber) {
    return `WR ${workRequestNumber}`;
  }
  if (workOrderNumber) {
    return `WO ${workOrderNumber}`;
  }
  return null;
};

const OverrideRecords = () => {
  const { associatedOverrideRecords, deleteAssociatedRecord } = useOverrideDetails();
  return associatedOverrideRecords.length > 0 ? (
    <>
      {associatedOverrideRecords.map((record, index) => {
        const title = getTitle(record);
        return (
          <OverrideRecordCard
            key={index}
            recordDetails={title ? { title, description: null, status: null, location: null } : null}
            actionButtonColour={theme.colours.error.primary}
            actionButtonIcon={<RemoveCircleIcon />}
            actionButtonText="Remove"
            handleActionButtonClick={() => deleteAssociatedRecord(record)}
          />
        );
      })}
    </>
  ) : (
    <p>There are no added records.</p>
  );
};

export default OverrideRecords;
