import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';

const SectionTitle = styled(Typography)<{ $noColour?: boolean }>`
  font: ${({ theme }) => theme.fonts.bold16};
  color: ${({ $noColour, theme }) => ($noColour ? theme.colours.greys.darkGrey5 : theme.colours.teals.teal1)};
  margin-bottom: 16px;

  &:not(:first-of-type) {
    margin-top: 32px;
  }
`;

interface DialogSectionProps extends React.PropsWithChildren {
  title: string;
  noColour?: boolean;
}

const DialogSection: React.FC<DialogSectionProps> = ({ children, title, noColour = false }) => (
  <>
    <SectionTitle $noColour={noColour}>{title}</SectionTitle>
    {children}
  </>
);

export default DialogSection;
