import React, { useState, useEffect, createContext, useCallback, useContext } from 'react';
import api from '../api';
import { MSMProgram } from '../types/msm-programs';
import * as R from 'ramda';

interface MSMProgramsContextData {
  msmPrograms: MSMProgram[];
  addMSMProgram: (msmProgram: string, status: string) => Promise<void>;
  editMSMProgram: (msmProgram: string, status: string, originalMSMProgram: string) => Promise<void>;
  deleteMSMProgram: (msmProgram: string) => Promise<void>;
  loading: boolean;
  error: string | null;
}

export const MSMProgramsContext = createContext<MSMProgramsContextData>({
  msmPrograms: [],
  addMSMProgram: () => Promise.resolve(),
  editMSMProgram: () => Promise.resolve(),
  deleteMSMProgram: () => Promise.resolve(),
  loading: false,
  error: null,
});

const MSMProgramsProvider = (props: React.PropsWithChildren) => {
  const [msmPrograms, setMSMPrograms] = useState<MSMProgram[]>([{ msm_program: 'default', status: 'Active' }]);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const getMSMPrograms = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const newPrograms = await api.getMSMPrograms();
      if (newPrograms.length > 0) {
        setMSMPrograms(newPrograms);
      } else {
        setError('No MSM Programs found');
      }
    } catch (err) {
      setError('Failed to fetch MSM programs');
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const addMSMProgram = async (msmProgram: string, status: string) => {
    setLoading(true);
    setError(null);
    try {
      await api.addMSMProgram(msmProgram, status);
      await getMSMPrograms();
    } catch (err) {
      setError('Failed to add MSM program');
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const editMSMProgram = async (msmProgram: string, status: string, originalMSMProgram: string) => {
    setLoading(true);
    setError(null);
    try {
      await api.editMSMProgram(msmProgram, status, originalMSMProgram);
      await getMSMPrograms();
    } catch (err) {
      setError('Failed to edit MSM program');
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const deleteMSMProgram = async (msmProgram: string) => {
    setLoading(true);
    setError(null);
    try {
      await api.deleteMSMProgram(msmProgram);
      await getMSMPrograms();
    } catch (err) {
      setError('Failed to delete MSM program');
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void getMSMPrograms();
  }, [getMSMPrograms]);

  return (
    <MSMProgramsContext.Provider
      value={{ msmPrograms, loading, error, addMSMProgram, editMSMProgram, deleteMSMProgram }}
    >
      {props.children}
    </MSMProgramsContext.Provider>
  );
};

export const useMSMPrograms = () => {
  const context = useContext(MSMProgramsContext);
  if (R.isNil(context)) {
    throw new Error('useMSMPrograms must be used inside an BusinessEquipmentRiskProvider');
  }
  return context;
};

export default MSMProgramsProvider;
