export const temp = () => <p>uh</p>;
import { ControlRadio } from '../../../../form/ControlRadio';
import { ControlTextField } from '../../../../form/ControlTextField';
import Grid from '@material-ui/core/Grid';
import { Control, FieldErrors } from 'react-hook-form';
import { RiskApproverFormInputs } from '../../../../types/risk-management';
import { UppercaseTitle } from './UppercaseTitle';
import { DecisionOptions } from '../hooks/useRiskApproverForm';

export type DecisionTypeInfoProps = {
  control: Control<RiskApproverFormInputs>;
  errors: FieldErrors<RiskApproverFormInputs>;
  decisionOptions: any;
  escalationDecisionOptions: DecisionOptions;
  watchEscalateIssue: string | null;
  isDecisionDescRequired: boolean;
  isReadOnly: boolean;
  title: boolean;
  escalation: boolean;
};

export const DecisionTypeInfo = (props: DecisionTypeInfoProps) => {
  const {
    control,
    errors,
    decisionOptions,
    escalationDecisionOptions,
    watchEscalateIssue,
    isDecisionDescRequired,
    isReadOnly,
    title,
    escalation,
  } = props;

  return (
    <>
      {title && watchEscalateIssue === 'yes' && (
        <Grid
          item
          xs={12}
        >
          <UppercaseTitle>Decision Type Recommendation</UppercaseTitle>
        </Grid>
      )}
      {watchEscalateIssue === 'no' ? (
        <>
          <Grid
            item
            xs={12}
          >
            <ControlRadio
              control={control}
              required
              label="Decision Type"
              name="decisionType"
              options={escalation ? escalationDecisionOptions : decisionOptions}
              error={!!errors.decisionType}
              readOnly={isReadOnly}
              row
            />
          </Grid>
          {isDecisionDescRequired && (
            <Grid
              item
              xs={12}
            >
              <ControlTextField
                control={control}
                required
                name="decisionTypeDescription"
                error={!!errors.decisionTypeDescription}
                label="Decision Type Description"
                readOnly={isReadOnly}
              />
            </Grid>
          )}
        </>
      ) : (
        <>
          <Grid
            item
            xs={12}
          >
            <ControlRadio
              control={control}
              required
              label="Decision Type"
              name="recommendedDecisionType"
              options={escalationDecisionOptions}
              error={!!errors.decisionType}
              readOnly={isReadOnly}
              row
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <ControlTextField
              control={control}
              required
              name="recommendedDecisionTypeDescription"
              error={!!errors.decisionTypeDescription}
              label="Rationale for Recommendation"
              readOnly={isReadOnly}
              multiline={true}
              minRows={6}
              minLength={1}
              maxLength={3000}
              placeholder="Required (Character Limitation: 3000 characters)"
            />
          </Grid>
        </>
      )}
    </>
  );
};
