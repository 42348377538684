import { useActions } from '../../hooks/useActions';
import ActionsTable from './ActionsTable';
import '@inovua/reactdatagrid-community/index.css';
import styled from 'styled-components';
import ActionButton from '../../../../components/ActionButton';
import Page from '../../../../components/Page';
import { Box, Grid, Tooltip, Typography, FormControlLabel, Switch, IconButton } from '@material-ui/core';
import UnstyledFormDialog from '../../../../components/FormDialog';
import AddActionForm from './AddActionForm';
import { WODetailsModalContextProvider } from '../../hooks/useWODetailsModal';
import WODetailsModal from '../../components/WODetailsModal';
import { useWorkOrders } from '../../hooks/useWorkOrders';
import AutocompleteInput from '../../../../components/AutocompleteInput';
import {
  getPriorityOptions,
  getUnitOptions,
  getWorkTypeOptions,
  getWorkorderOptions,
  getWorkGroupOptions,
  getOnlineStartOptions,
} from '../../wo/components/utils';
import { useUsers } from '../../hooks/useUsers';
import DownloadButton from '../../../../components/DownloadButton';
import { useMemo } from 'react';
import AutorenewIcon from '@material-ui/icons/Autorenew';

const AddActionButton = styled(ActionButton)`
  margin-bottom: 10px;
`;

const FormDialog = styled(UnstyledFormDialog)`
  .MuiDialogContent-root {
    max-height: 600px;
    max-width: 500px;
    min-width: 500px;
  }
`;

const WaitSwitch = styled(Switch)`
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: red;
  }
  .MuiSwitch-colorSecondary.Mui-checked:hover {
    background-color: rgba(0, 255, 0, 0.04);
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: red;
  }
`;

const RefreshBacklogIcon = styled(AutorenewIcon)`
  cursor: pointer;
  padding-top: 0;
  color: ${({ theme }) => theme.colours.teals.teal1};
  width: 25px;
  height: 25px;
`;

const ActionsHeader = () => {
  const {
    groups,
    statuses,
    flags,
    loading,
    setOpenAddEditActionDialog,
    reset,
    openAddEditActionDialog,
    handleSubmit,
    filters,
    setFilters,
    fetchActions,
    handleOpenAddActionDialog,
    exportCSV,
    filteredActionsWithWorkorder,
  } = useActions();
  const { workorders } = useWorkOrders();
  const cancelAddAction = () => {
    setOpenAddEditActionDialog(false);
    reset();
  };
  const { users } = useUsers();

  return (
    <Page title="Actions">
      <>
        <Grid
          container
          spacing={2}
          style={{ marginLeft: '10px' }}
        >
          <Grid
            item
            xs={12}
          >
            <Box
              width="20%"
              height="100px"
              minWidth={'120px'}
              borderRadius="5px"
              textAlign="center"
              boxShadow="rgb(191, 190, 190) 0px 2px 7px 0px;"
              marginRight="10px"
              marginTop="10px"
            >
              <Typography style={{ fontWeight: '500', fontSize: '45px' }}>
                {loading ? '...' : filteredActionsWithWorkorder.length}
              </Typography>
              <Typography style={{ fontSize: '12px' }}>Current Actions</Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={3}
          >
            <AutocompleteInput
              label="Work Order"
              options={getWorkorderOptions(workorders)}
              onChange={value => {
                setFilters({ ...filters, wonum: value });
              }}
              value={filters.wonum}
              multiple
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteInput
              label="Unit"
              options={getUnitOptions(workorders)}
              onChange={value => {
                setFilters({ ...filters, unit: value });
              }}
              value={filters.unit}
              multiple
              selectAll
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteInput
              label="Priority"
              options={getPriorityOptions(workorders)}
              onChange={value => {
                setFilters({ ...filters, wopriority: value });
              }}
              value={filters.wopriority}
              multiple
              selectAll
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteInput
              label="Work Type"
              options={getWorkTypeOptions(workorders)}
              onChange={value => {
                setFilters({ ...filters, worktype: value });
              }}
              value={filters.worktype}
              multiple
              selectAll
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteInput
              label="Crew"
              options={getWorkGroupOptions(workorders)}
              onChange={value => {
                setFilters({ ...filters, crewworkgroup: value });
              }}
              value={filters.crewworkgroup}
              multiple
              selectAll
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteInput
              label="Action Owner"
              options={users
                .map(user => ({ value: user.id?.toString(), label: user.name }))
                .sort((a, b) => a.label.localeCompare(b.label))}
              onChange={value => {
                setFilters({ ...filters, owner: value });
              }}
              value={filters.owner}
              multiple
              selectAll
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteInput
              label="Action Group"
              options={groups.map(group => ({ value: group.id?.toString(), label: group.label }))}
              onChange={value => {
                setFilters({ ...filters, groupId: value });
              }}
              value={filters.groupId}
              multiple
              selectAll
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteInput
              label="Status"
              options={statuses.map(status => ({ value: status.id?.toString(), label: status.label }))}
              onChange={value => {
                setFilters({ ...filters, statusId: value });
              }}
              value={filters.statusId}
              multiple
              selectAll
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteInput
              label="Flags"
              options={flags.map(flag => ({ value: flag.id?.toString(), label: flag.label }))}
              onChange={value => {
                setFilters({ ...filters, flags: value });
              }}
              value={filters.flags}
              multiple
              selectAll
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteInput
              label="Online Start"
              options={useMemo(
                () => getOnlineStartOptions(filteredActionsWithWorkorder),
                [filteredActionsWithWorkorder]
              )}
              onChange={value => {
                setFilters({ ...filters, schedstart: value });
              }}
              value={filters.schedstart}
              multiple
            />
          </Grid>
          <Grid
            item
            xs={6}
          ></Grid>
          <FormControlLabel
            control={
              <WaitSwitch
                checked={filters.highPriority}
                onChange={e => {
                  setFilters({
                    ...filters,
                    highPriority: e.target.checked,
                  });
                }}
                name="highPriority"
              />
            }
            label="High Priority"
          />
          <Grid
            item
            xs={9}
          ></Grid>
        </Grid>
        <Box
          display="flex"
          justifyContent={'space-between'}
          alignContent={'center'}
          marginBottom="10px"
          marginTop="10px"
          marginLeft="10px"
        >
          <Grid>
            <Tooltip
              title="Refresh Backlog"
              placement="right"
            >
              <IconButton onClick={fetchActions}>
                <RefreshBacklogIcon />
              </IconButton>
            </Tooltip>
            <AddActionButton
              color="primary"
              variant="text"
              onClick={() => handleOpenAddActionDialog()}
            >
              Add Action
            </AddActionButton>
          </Grid>
          <Tooltip
            title="Export CSV"
            placement="top"
          >
            <DownloadButton onClick={exportCSV}></DownloadButton>
          </Tooltip>
        </Box>

        {openAddEditActionDialog && (
          <FormDialog
            title="Add Action"
            onSave={handleSubmit}
            onCancel={cancelAddAction}
          >
            <AddActionForm />
          </FormDialog>
        )}
        <WODetailsModalContextProvider>
          <WODetailsModal />
          <ActionsTable />
        </WODetailsModalContextProvider>
      </>
    </Page>
  );
};

export default ActionsHeader;
