import {
  DecisionTypeRiskApprover,
  RiskEscalationRoles,
  RiskApproverFormInputs,
  RiskEscalationTableFields,
  USER_ROLES,
} from '../../../../types/risk-management';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Control, FieldErrors, SubmitHandler, useForm, UseFormSetValue } from 'react-hook-form';
import api from '../../../../api';
import { useLocation, useHistory } from 'react-router-dom';
import * as uiActions from '../../../../state/ui/actions';
import { useDispatch } from 'react-redux';
import { ACCEPT_AND_KEEP_ACTIVE, ACCEPT_AND_CLOSE, REJECT_AND_RESUBMIT, REJECT_AND_CLOSE } from '../../constants';
import { useRiskManagementUsers } from '../../../../context/business-equipment-risk-admin';
import { SubmitTextStatus } from '../SubmitStatusText';
import isFeatureEnabled from '../../../../utils/feature-flags';
import { buildApproverSubmission, buildEscalationSubmission, getEscalationNeighbours } from './utils';

export type DecisionOptions = { value: DecisionTypeRiskApprover; label: string }[];

export type RiskApproverForm = {
  loading: boolean;
  control: Control<RiskApproverFormInputs>;
  handleSubmit: () => Promise<void>;
  submitTextStatus: SubmitTextStatus;
  errors: FieldErrors<RiskApproverFormInputs>;
  decisionOptions: DecisionOptions;
  escalationDecisionOptions: DecisionOptions;
  yesOrNoOptions: { value: string; label: string }[];
  watchEscalateIssue: string | null;
  watchFlagToCno: string | null;
  isReadOnly: boolean;
  isSubmitReady: boolean;
  isDecisionDescRequired: boolean;
  newApproverForm: boolean;
  setValue: UseFormSetValue<RiskApproverFormInputs>;
  escalations: any[];
  hasEscalations: boolean;
  lastEscalatedToRole: RiskEscalationRoles;
  approvedBy: string | undefined;
};

export const INITIAL_VALUES: RiskApproverFormInputs = {
  escalateIssue: null,
  escalateIssueRationale: '',
  escalateUsers: [],
  escalateTo: '',
  decisionType: null,
  decisionTypeDescription: '',
  flagToCno: null,
  cnoDescription: '',
  cnoBridgingStrategy: '',
  cnoStatusUpdate: '',
  riskResponseSummary: '',
  recommendedDecisionType: null,
  recommendedDecisionTypeDescription: '',
};

export const useRiskApproverForm = (riskManagementId: number): RiskApproverForm => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const currentUserRoles = useRiskManagementUsers();
  const [loading, setLoading] = useState<boolean>(false);
  const [submitTextStatus, setSubmitTextStatus] = useState<SubmitTextStatus>('default');
  const [newApproverForm, setNewApproverForm] = useState<boolean>(true);
  const [escalations, setEscalations] = useState<RiskEscalationTableFields[]>([]);
  const [lastEscalatedToRole, setLastEscalatedToRole] = useState<RiskEscalationRoles>('sectionManager');
  const [approvedBy, setApprovedBy] = useState<string>('');

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
    reset,
  } = useForm<RiskApproverFormInputs>({
    defaultValues: INITIAL_VALUES,
  });

  useEffect(() => {
    const fetchRiskApprover = async () => await getRiskApproverForm();
    const fetchRiskEscalations = async () => await getRiskEscalations();

    if (pathname.includes('/archive')) {
      void fetchRiskApprover();
    } else {
      void fetchRiskEscalations();
      reset(INITIAL_VALUES);
      setLoading(false);
      setSubmitTextStatus('default');
      setNewApproverForm(isFeatureEnabled('riskEscalationOverhaul'));
    }
  }, [pathname, riskManagementId]);

  const resetSubmitState = () => {
    setSubmitTextStatus('default');
    setLoading(false);
  };

  const onSubmit: SubmitHandler<RiskApproverFormInputs> = async ({ ...data }) => {
    setLoading(true);
    setSubmitTextStatus('submitting');
    try {
      if (data.escalateIssue === 'no' || lastEscalatedToRole === 'cno') {
        await api.submitRiskApproverForm(
          {
            ...buildApproverSubmission({ data, newApproverForm, hasBeenEscalated: !!escalations?.length }),
            escalateUsers: [],
          },
          riskManagementId
        );
        switch (data.decisionType) {
          case 'acceptAndMonitor':
          case 'acceptAndClose':
            dispatch(uiActions.genericMessage('Risk Approved...'));
            break;
          case 'rejectAndResubmit':
          case 'rejectAndClose':
          default:
            dispatch(uiActions.genericMessage('Risk Rejected...'));
        }
      } else if (data.escalateIssue === 'yes') {
        await api.submitRiskEscalation(
          {
            ...buildEscalationSubmission({
              data,
              escalateToRole: getEscalationNeighbours(lastEscalatedToRole).after as RiskEscalationRoles,
              riskManagementId,
              escalationWorkflowStep: escalations.length > 0 ? escalations[0].escalationWorkflowStep + 1 : 1,
            }),
          },
          riskManagementId
        );
        dispatch(uiActions.genericMessage('Risk Escalated...'));
      }

      setSubmitTextStatus('success');
      setTimeout(() => {
        // This might run after the user has already navigated away
        // Therefore, check that the window's current location has not changed before navigating
        if (window.location.pathname === pathname) {
          push('/app/business-equipment-risks/risk-review');
        }
        resetSubmitState();
      }, 3000);
    } catch (e) {
      dispatch(uiActions.error('', 'Error: unable to submit decision'));
      resetSubmitState();
    }
  };

  /** @deprecated to be replaced by onSubmit once feature flag is removed */
  const oldOnSubmit: SubmitHandler<RiskApproverFormInputs> = async ({ escalateUsers, ...data }) => {
    setLoading(true);
    setSubmitTextStatus('submitting');
    try {
      await api.submitRiskApproverForm({ ...data, newApproverForm, escalateUsers }, riskManagementId);
      switch (data.decisionType) {
        case 'acceptAndMonitor':
        case 'acceptAndClose':
          dispatch(uiActions.genericMessage('Risk Approved...'));
          break;
        case 'rejectAndResubmit':
        case 'rejectAndClose':
        default:
          dispatch(uiActions.genericMessage('Risk Rejected...'));
      }
      setSubmitTextStatus('success');
      setTimeout(() => {
        push('/app/business-equipment-risks/risk-review');
        setSubmitTextStatus('default');
      }, 3000);
    } catch {
      dispatch(uiActions.error('', 'Error: unable to submit decision'));
    }
    setLoading(false);
  };

  const fetchSortedRiskEscalationData = useCallback(async () => {
    const escalationData = await api.getRiskEscalations(riskManagementId);
    return [...escalationData].sort((a, b) => b.escalationWorkflowStep - a.escalationWorkflowStep);
  }, [riskManagementId]);

  const getRiskApproverForm = useCallback(async () => {
    try {
      if (!riskManagementId) return null;
      const risk = await api.getRiskApproverForm(riskManagementId);
      const sortedEscalations = await fetchSortedRiskEscalationData();

      if (sortedEscalations.length > 0) {
        setEscalations(sortedEscalations);
        setLastEscalatedToRole(sortedEscalations[0].escalateToRole);
      }

      reset({ ...risk }, { keepDirty: false });
      setApprovedBy(risk.createdBy);
      setNewApproverForm(risk.newApproverForm);
      return;
    } catch {
      dispatch(uiActions.error('', 'Error: unable to load Risk Approver data'));
      return;
    }
  }, [riskManagementId]);

  const getRiskEscalations = useCallback(async () => {
    try {
      const sortedEscalations = await fetchSortedRiskEscalationData();

      if (sortedEscalations.length > 0) {
        setEscalations(sortedEscalations);
        setLastEscalatedToRole(sortedEscalations[0].escalateToRole);

        reset({
          cnoDescription: sortedEscalations[0].riskDescription,
          cnoBridgingStrategy: sortedEscalations[0].riskBridgingStrategy,
          cnoStatusUpdate: sortedEscalations[0].riskStatusUpdate,
          riskResponseSummary: sortedEscalations[0].riskResponseSummary,
          ...(sortedEscalations[0].escalateToRole === 'cno' && { escalateIssue: 'no' }),
        });
      }
      return;
    } catch {
      dispatch(uiActions.error('', 'Error: unable to load Risk Escalation data'));
      return;
    }
  }, [riskManagementId]);

  const decisionOptions: DecisionOptions = useMemo(() => {
    return [
      { value: 'acceptAndMonitor', label: ACCEPT_AND_KEEP_ACTIVE },
      { value: 'acceptAndClose', label: ACCEPT_AND_CLOSE },
      { value: 'rejectAndClose', label: REJECT_AND_CLOSE },
      { value: 'rejectAndResubmit', label: REJECT_AND_RESUBMIT },
    ];
  }, []);

  const escalationDecisionOptions: DecisionOptions = useMemo(() => {
    return [
      { value: 'acceptAndMonitor', label: ACCEPT_AND_KEEP_ACTIVE },
      { value: 'acceptAndClose', label: ACCEPT_AND_CLOSE },
    ];
  }, []);

  const yesOrNoOptions = useMemo(() => {
    return [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    ];
  }, []);

  const isDecisionDescRequired =
    watch('decisionType') === 'rejectAndClose' ||
    watch('decisionType') === 'rejectAndResubmit' ||
    watch('decisionType') === 'acceptAndClose';

  return {
    loading,
    control,
    handleSubmit: handleSubmit(isFeatureEnabled('riskEscalationOverhaul') ? onSubmit : oldOnSubmit),
    submitTextStatus,
    errors,
    decisionOptions,
    escalationDecisionOptions,
    yesOrNoOptions,
    watchEscalateIssue: watch('escalateIssue'),
    watchFlagToCno: watch('flagToCno'),
    isReadOnly: pathname.includes('/archive') || !currentUserRoles.includes(USER_ROLES.APPROVER),
    isSubmitReady: isValid,
    isDecisionDescRequired,
    newApproverForm,
    setValue,
    escalations: escalations.slice().reverse(),
    hasEscalations: !!escalations?.length,
    lastEscalatedToRole,
    approvedBy: pathname.includes('/archive') ? approvedBy : undefined,
  };
};
