import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import CarlaText from '../../../../../../components/CarlaText';
import DialogButton from '../../../../../../components/DialogButton';
import CustomTextField from '../../../../../../components/CustomTextField';
import Select from '../../../../../../components/Select';
import { useCallback, useEffect, useState } from 'react';
import * as uiActions from '../../../../../../state/ui/actions';
import { useDispatch } from 'react-redux';
import { useOutagePlans } from '../../../../../../context/outage-plans';
import LoadingContainer from '../../../../../../components/LoadingContainer';

type Props = {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  originalOutageCode: string;
  originalStatus: string;
};

const EditOutagePlanDashboard = ({ opened, setOpened, originalOutageCode, originalStatus }: Props) => {
  const [outageCode, setOutageCode] = useState(originalOutageCode);
  const [status, setStatus] = useState(originalStatus);
  const dispatch = useDispatch();
  const { editOutagePlan, loading, error } = useOutagePlans();

  const handleEdit = useCallback(async () => {
    if (outageCode && status) {
      try {
        await editOutagePlan(outageCode, status, originalOutageCode);
        dispatch(uiActions.genericMessage('Updated Succesfully'));
        setOpened(false);
      } catch {
        dispatch(uiActions.error('', error ?? 'Update Unsuccesful'));
      }
    } else {
      dispatch(uiActions.error('', 'Please enter a valid input'));
    }
  }, [outageCode, status]);

  useEffect(() => {
    setOutageCode(originalOutageCode);
    setStatus(originalStatus);
  }, [originalOutageCode, originalStatus]);

  return (
    <Dialog
      open={opened}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Edit outage code</DialogTitle>
      <DialogContent>
        {loading ? (
          <LoadingContainer loading={loading} />
        ) : (
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <CarlaText>Edit outage code and set status</CarlaText>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <CustomTextField
                label="Outage Code"
                inputProps={{ maxLength: 5 }}
                onChange={e => setOutageCode(e.target.value)}
                defaultValue={originalOutageCode}
              ></CustomTextField>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Select
                fullWidth
                label="Status"
                options={[
                  { label: 'Planned', value: 'Planned' },
                  { label: 'Complete', value: 'Complete' },
                ]}
                onChange={e => setStatus(e.target.value)}
                value={status}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <DialogButton
          onClick={() => {
            setStatus(originalStatus);
            setOpened(false);
          }}
        >
          Cancel
        </DialogButton>
        <DialogButton
          color="primary"
          onClick={handleEdit}
        >
          Update
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditOutagePlanDashboard;
