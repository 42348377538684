import React from 'react';

import styled from 'styled-components';
import useIndicatorRecordId from '../../hooks/indicator-record-id';
import SortableTable from '../SortableTable';
import useIndicatorRecordColumns from './hooks/indicator-record-columns';
import useSort from './hooks/sort';
import useSortedItems from './hooks/sorted-items';
import { SortableColumn } from '../SortableTable/types';
import isFeatureEnabled from '../../utils/feature-flags';

const Container = styled.div`
  padding-left: 1rem;
  padding-right: 21px;
  ${isFeatureEnabled('liveScorecardOverrides')
    ? `
  padding-bottom: 12px;

  // Add left padding to the first cell in each row
  // Padding is not supported on tr elements, so must be applied to the cell instead
  tr > :first-child {
    padding-left: 4px;
  }
    `
    : ''}
`;

interface Props {
  indicatorShortName: string;
  items: any;
  extraColumns: Array<SortableColumn>;
}

const IndicatorRecordTableWithExtraColumns = ({ indicatorShortName, items, extraColumns }: Props) => {
  const [sortColumn, sortOrder, setSort] = useSort();
  const sortedRecords = useSortedItems(items, sortColumn, sortOrder);
  const indicatorRecordId = useIndicatorRecordId(indicatorShortName);
  const tableColumns = useIndicatorRecordColumns(indicatorShortName);

  const actualColumns: Array<SortableColumn> = [...tableColumns, ...extraColumns];

  return (
    <Container>
      <SortableTable
        columns={actualColumns}
        indicatorRecordId={indicatorRecordId}
        records={sortedRecords}
        sortColumn={sortColumn}
        sortOrder={sortOrder}
        onSort={setSort}
      />
    </Container>
  );
};

export default IndicatorRecordTableWithExtraColumns;
