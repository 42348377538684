import * as R from 'ramda';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import useForm from '../../../../../hooks/form';
import useQuarters, { toQuarterValue, fromQuarterValue } from '../../../../../hooks/quarters';
import useAllAttachmentTypes from '../../../../../hooks/all-attachment-types';
import Select from '../../../../../components/Select';
import CarlaText from '../../../../../components/CarlaText';
import DialogButton from '../../../../../components/DialogButton';
import FileUploadField from '../../../../../components/FileUploadField';
import { MyElement } from '../../../../../types/my';
import isFeatureEnabled from '../../../../../utils/feature-flags';

const formConfig = {
  quarter: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Select a quarter',
      },
    },
  },
  attachmentType: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Select a file type',
      },
    },
  },
  file: {
    mapValueFn: R.identity,
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Select a file',
      },
    },
  },
};

const initialValues = (quarter: string, attachmentType: string) => ({
  quarter: quarter || '',
  attachmentType: attachmentType || '',
  file: '',
});

interface Props {
  element: MyElement;
  initialQuarter: string;
  initialAttachmentType: string;
  onAdd: (year: string, quarter: string, attachmentType: string, file: File) => void;
  onCancel: any;
}

const AddAttachmentDialog = ({ element, initialQuarter, initialAttachmentType, onAdd, onCancel }: Props) => {
  const handleSubmit = (values: any) => {
    const [year, quarter] = fromQuarterValue(values.quarter);
    onAdd(year, quarter, values.attachmentType, values.file);
  };

  const { submit, propsForField } = useForm(
    formConfig,
    initialValues(initialQuarter, initialAttachmentType),
    handleSubmit
  );
  const { quarters } = useQuarters({ n: 10, forward: false });
  const { attachmentTypes } = useAllAttachmentTypes();

  const quarterOptions = quarters.map(q => ({
    value: toQuarterValue(q.year, q.quarter),
    label: `Q${q.quarter} - ${q.year}`,
  }));

  const preFilteredAttachmentTypes = isFeatureEnabled('walkdownFileAttachmentType')
    ? attachmentTypes
    : attachmentTypes.filter((att: any) => att.id !== 'walkdown');

  const attachmentTypeOptions = preFilteredAttachmentTypes
    .filter((att: any) => {
      if (!element) {
        return true;
      }
      if (element.elementType === 'system' && att.id !== 'cpmp') {
        return true;
      }
      if (element.elementType === 'component' && att.id !== 'spmp') {
        return true;
      }
      return false;
    })
    .map((attachmentType: any) => ({
      value: attachmentType.id,
      label: attachmentType.name,
    }));

  return (
    <Dialog open={true}>
      <DialogTitle>Add an attachment</DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          justifyContent="center"
          spacing={2}
        >
          <Grid item>
            <CarlaText>Select a quarter and type for your attachment.</CarlaText>
          </Grid>
          <Grid item>
            <Select
              label="Quarter"
              fullWidth
              options={quarterOptions}
              {...propsForField('quarter')}
            />
          </Grid>
          <Grid item>
            <Select
              label="Type"
              fullWidth
              options={attachmentTypeOptions}
              {...propsForField('attachmentType')}
            />
          </Grid>
          <Grid item>
            <FileUploadField {...propsForField('file')} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogButton onClick={onCancel}>Cancel</DialogButton>
        <DialogButton
          color="primary"
          onClick={submit}
        >
          Continue
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddAttachmentDialog;
