import { call, put, takeEvery } from 'redux-saga/effects';
import renameKey from '../../utils/rename-key';
import Api from '../../api';
import * as actionTypes from '../action-types';
import * as actions from './actions';
import * as scorecardActions from '../scorecard/actions';

function* load(action) {
  const { elementId, indicatorId } = action.payload;
  try {
    const records = yield call(Api.getIndicatorRecordsByElement, elementId, indicatorId);
    yield put(actions.loadSuccess(elementId, indicatorId, records));
  } catch (e) {
    yield put(actions.loadFailure(e, { elementId, indicatorId }));
  }
}

function* loadByQuarter(action) {
  const { elementId, year, quarter, indicatorId, applicable } = action.payload;
  try {
    const records = yield call(Api.getIndicatorRecordsByQuarter, elementId, year, quarter, indicatorId, applicable);
    yield put(actions.loadByQuarterSuccess(elementId, year, quarter, indicatorId, records));
  } catch (e) {
    yield put(actions.loadByQuarterFailure(e, { elementId, year, quarter, indicatorId }));
  }
}

function* updateApplicability(action) {
  const { elementId, year, quarter, indicatorId, indicatorShortName, recordId, applicable, options } = action.payload;
  try {
    yield call(Api.updateIndicatorRecord, indicatorId, recordId, applicable, options);
    yield put(
      actions.updateApplicabilitySuccess(
        elementId,
        year,
        quarter,
        indicatorId,
        indicatorShortName,
        recordId,
        applicable,
        renameKey('description', 'applicabilityDescription', options)
      )
    );
    // Refresh scorecard after changing record applicability
    yield put(scorecardActions.loadScorecardRequest(elementId, year, quarter));
  } catch (e) {
    yield put(
      actions.updateApplicabilityFailure(e, { elementId, year, quarter, indicatorId, recordId, applicable, options })
    );
  }
}

function* root() {
  yield takeEvery(actionTypes.INDICATOR_RECORDS_LOAD_REQUEST, load);
  yield takeEvery(actionTypes.INDICATOR_RECORDS_LOAD_BY_QUARTER_REQUEST, loadByQuarter);
  yield takeEvery(actionTypes.INDICATOR_RECORDS_UPDATE_APPLICABILITY_REQUEST, updateApplicability);
}

export default root;
