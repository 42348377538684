import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import styled from 'styled-components';
import React from 'react';

export type RecordTableContainerProps = CollapsibleRecordTableContainerProps | NonCollapsibleRecordTableContainerProps;

interface BaseRecordTableContainerProps {
  title?: string;
  count?: number;
}

interface NonCollapsibleRecordTableContainerProps extends BaseRecordTableContainerProps {
  collapsible?: false;
  expanded?: never;
  toggleVisibility?: never;
}

interface CollapsibleRecordTableContainerProps extends BaseRecordTableContainerProps {
  collapsible: true;
  expanded: boolean;
  toggleVisibility: () => void;
}

const TitleContainer = styled.div`
  color: ${({ theme }) => theme.colours.teals.teal1};
`;

const TableTitle = styled(Typography)`
  font: ${({ theme }) => theme.fonts.bold14};
  text-transform: uppercase;
`;

const TableContainer = styled.div`
  background-color: #f5f5f5;
  padding-bottom: 8px;

  &:first-of-type {
    padding-top: 8px;
  }
`;

const RecordTableContainer: React.FC<React.PropsWithChildren<RecordTableContainerProps>> = ({
  children,
  collapsible,
  expanded,
  count,
  title,
  toggleVisibility,
}) => {
  return (
    <TableContainer>
      {title && (
        <TitleContainer>
          {collapsible && (
            <IconButton
              color="inherit"
              onClick={toggleVisibility}
            >
              {expanded ? (
                <KeyboardArrowUpIcon titleAccess="Collapse" />
              ) : (
                <KeyboardArrowDownIcon titleAccess="Expand" />
              )}
            </IconButton>
          )}
          <TableTitle display="inline">
            {title}
            {Number.isInteger(count) ? ` (${count})` : ''}
          </TableTitle>
        </TitleContainer>
      )}
      {collapsible ? (
        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
        >
          {children}
        </Collapse>
      ) : (
        children
      )}
    </TableContainer>
  );
};

export default RecordTableContainer;
