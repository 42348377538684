import * as actionTypes from '../../../../../state/action-types';

const initialState = {
  records: {},
  recordDetails: {},
  filters: {},
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.RECORD_DETAILS_LOAD_RECORD_DETAILS_REQUEST: {
      return {
        ...state,
        recordDetails: {
          isLoading: true,
        },
      };
    }
    case actionTypes.RECORD_DETAILS_LOAD_RECORD_DETAILS_SUCCESS: {
      const { recordDetails } = action.payload;
      return {
        ...state,
        recordDetails: {
          ...recordDetails,
          isLoading: false,
        },
      };
    }
    case actionTypes.RECORD_DETAILS_LOAD_RECORD_DETAILS_FAILURE: {
      return {
        ...state,
        recordDetails: {
          error: true,
          isLoading: false,
        },
      };
    }
    case actionTypes.RECORD_DETAILS_SET_FILTER: {
      const { name, value } = action.payload;
      return {
        ...state,
        records: {},
        selectedRecord: undefined,
        recordDetails: {},
        filters: {
          ...state.filters,
          [name]: value,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
