import Box, { BoxProps } from '@material-ui/core/Box';
import Button, { ButtonProps } from '@material-ui/core/Button';
import UnstyledCard from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import AssessmentIcon from '@material-ui/icons/Assessment';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import styled from 'styled-components';
import { OverrideRecordDetails } from './types';

const Card = styled(UnstyledCard)`
  &:not(:first-of-type) {
    margin-top: 8px;
  }
`;

const Title = styled(Typography)`
  font: ${({ theme }) => theme.fonts.bold16};
`;

const DetailContainer = styled(Box).attrs<BoxProps>({
  display: 'flex',
  alignItems: 'center',
})`
  color: ${({ theme }) => theme.colours.greys.darkGrey5};
`;

const IconLabel = styled(Typography)`
  padding-left: 4px;
`;

const ActionButton = styled(Button).attrs<ButtonProps & { $color: React.CSSProperties['color'] }>(props => ({
  color: props.$color ? 'inherit' : 'default',
}))<{ $color: React.CSSProperties['color'] }>`
  color: ${props => props.$color};
`;

export interface OverrideRecordCardProps {
  recordDetails: OverrideRecordDetails | null;
  actionButtonColour?: React.CSSProperties['color'];
  actionButtonIcon: React.ReactNode;
  actionButtonText: string;
  handleActionButtonClick: () => void;
}

const OverrideRecordCard: React.FC<OverrideRecordCardProps> = ({
  recordDetails,
  actionButtonColour,
  actionButtonIcon,
  actionButtonText,
  handleActionButtonClick,
}) => {
  if (!recordDetails) {
    return null;
  }
  return (
    <Card>
      <CardContent>
        <Box width="100%">
          <Title>{recordDetails.title}</Title>
          {recordDetails.description && <Typography>{recordDetails.description}</Typography>}
          <Box
            display="grid"
            width="100%"
            pt={1}
            alignItems="center"
            gridTemplateColumns="repeat(2, 1fr) 2fr"
          >
            <DetailContainer>
              {recordDetails.location && (
                <>
                  <LocationOnIcon titleAccess="Location" />
                  <IconLabel>{recordDetails.location}</IconLabel>
                </>
              )}
            </DetailContainer>

            <DetailContainer>
              {recordDetails.status && (
                <>
                  <AssessmentIcon titleAccess="Status" />
                  <IconLabel>{recordDetails.status}</IconLabel>
                </>
              )}
            </DetailContainer>

            <Box
              display="flex"
              justifyContent="flex-end"
            >
              <ActionButton
                $color={actionButtonColour}
                startIcon={actionButtonIcon}
                onClick={handleActionButtonClick}
              >
                {actionButtonText}
              </ActionButton>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default OverrideRecordCard;
