import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import { formConfig } from '../config/singleWalkdownFormConfig';
import ActionButton from '../../../components/ActionButton';
import CustomTextField from '../../../components/CustomTextField';
import useForm from '../../../hooks/form';
import DateSelect from '../components/DateSelect';
import UploadAttachmentsPage from '../upload-attachments/UploadAttachmentsPage';
import { Walkdown, WalkdownCategory } from '../../../types/walkdown-types';
import WalkdownCategoryCard from './WalkdownCategoryCard';
import useUsers from '../../../hooks/users';
import useSingleWalkdown from '../hooks/useSingleWalkdown';
import Conditional from '../../../components/Conditional';
import elementTypePrefix from '../../../utils/element-type-prefix';
import { ReadOnlyProvider } from '../../../hooks/read-only';
import useElementUnits from '../../../hooks/element-units';
import AutocompleteInput from '../../../components/AutocompleteInput';
import styled from 'styled-components';

const getYearOptions = () => {
  const years = [];
  const dateStart = moment().subtract(1, 'year');
  const dateEnd = moment();
  while (dateEnd.diff(dateStart, 'years') >= 0) {
    years.push({ value: dateStart.format('YYYY'), label: dateStart.format('YYYY') });
    dateStart.add(1, 'year');
  }
  return years;
};

const PADDING = '10px';
const NONE = { value: '', label: '' };
const YEAR_OPTIONS = [NONE, ...getYearOptions()];

interface Props {
  walkdown: Walkdown;
  update(v: any): any;
  hideBanner?: boolean;
  readOnly?: boolean;
}

const StyledAutocompleteInput = styled(AutocompleteInput)<{ width?: string }>`
  width: ${props => props.width || '200px'};
`;

const SingleWalkdownInternal = ({ walkdown, update, hideBanner, readOnly }: Props) => {
  const { users } = useUsers();
  const { submitWalkdown } = useSingleWalkdown();
  const walkdownId = walkdown.id;
  const userOptions = useMemo(
    () =>
      users
        .map(({ name, id }: any) => ({
          label: name,
          value: id,
        }))
        .concat({
          label: 'Select User',
          value: '',
        }),
    [users]
  );
  const { elementUnits } = useElementUnits(walkdown.elementId);

  const unitOptions = useMemo(
    () =>
      elementUnits
        .map(({ elementUnit }: any) => ({
          label: elementUnit,
          value: elementUnit,
        }))
        .concat({
          label: 'Select Unit',
          value: '',
        }),
    [elementUnits]
  );

  const saveHandler = async (val: any) => {
    await update(val);
  };

  const { submit, propsForField, values, setField } = useForm(formConfig, walkdown, submitWalkdown);

  const approverSelectHandler = (val: any) => {
    setField('approver', val);
  };

  const personSelectHandler = (val: any) => {
    setField('person', val);
  };

  const yearSelectHandler = (val: any) => {
    setField('year', val);
  };

  const quarterSelectHandler = (val: any) => {
    setField('quarter', val);
  };

  const startDateSelectHandler = (val: any) => {
    setField('createdDate', val);
  };

  const completedDateSelectHandler = (val: any) => {
    setField('completedDate', val);
  };

  const unitSelectHandler = (val: any) => {
    setField('unit', val);
  };

  return (
    <div>
      <ReadOnlyProvider value={readOnly}>
        <Grid
          item
          style={{ textAlign: 'right' }}
        >
          <Conditional
            condition={walkdown.state === 'Submitted' || walkdown.state === 'Approved'}
            fallback={
              <>
                <ActionButton
                  style={{
                    marginRight: 20,
                  }}
                  variant="outlined"
                  color="primary"
                  onClick={() => saveHandler(values)}
                >
                  Save
                </ActionButton>
                <ActionButton
                  color="primary"
                  onClick={submit}
                >
                  Submit Walkdown
                </ActionButton>
              </>
            }
            contents={
              <div style={{ paddingTop: 20 }}>
                {!hideBanner && <Alert severity="success">Walkdown Submitted</Alert>}
                {!readOnly && (
                  <div>
                    <ActionButton
                      style={{
                        marginTop: 20,
                      }}
                      variant="outlined"
                      color="primary"
                      onClick={submit}
                    >
                      Save
                    </ActionButton>
                  </div>
                )}
              </div>
            }
          />
        </Grid>
        <Grid>
          <Grid
            item
            style={{ paddingTop: '2em' }}
          >
            <div>Element Name</div>
            <div style={{ padding: PADDING }}>
              <>
                [{elementTypePrefix(walkdown.elementType)}] {walkdown.elementName} ({walkdown.facilityName})
              </>
            </div>
          </Grid>
          <Grid
            item
            style={{ paddingTop: '2em' }}
          >
            <div>Template Title</div>
            <div style={{ padding: PADDING }}>{walkdown.templateTitle || ''}</div>
          </Grid>
          <Grid
            item
            style={{ paddingTop: '2em' }}
          >
            <div>Unit</div>
            <StyledAutocompleteInput
              label="Unit"
              value={values.unit}
              options={readOnly ? [{ value: walkdown.unit, label: walkdown.unit }] : unitOptions}
              onChange={unitSelectHandler}
              error={propsForField('unit').error}
              disabled={readOnly}
            />
          </Grid>
          <Grid
            item
            style={{ paddingTop: '2em' }}
          >
            <div>Walkdown Title</div>
            <CustomTextField
              variant="standard"
              {...propsForField('title')}
            />
          </Grid>
          <Grid
            item
            style={{ paddingTop: '2em' }}
          >
            <div>Select Approver</div>
            <Grid
              item
              style={{ paddingTop: '1em', width: '18em' }}
            >
              <StyledAutocompleteInput
                label="Approver"
                value={values.approver}
                options={readOnly ? [{ value: walkdown.approver, label: walkdown.approverName }] : userOptions}
                onChange={approverSelectHandler}
                error={propsForField('approver').error}
                disabled={readOnly}
              />
            </Grid>
            <Grid
              container
              style={{ paddingTop: '2em' }}
            >
              <Grid
                item
                style={{ paddingLeft: PADDING, paddingRight: PADDING }}
              >
                <StyledAutocompleteInput
                  label="Year"
                  value={`${values.year}`}
                  options={readOnly ? [{ value: `${walkdown.year}`, label: `${walkdown.year}` }] : YEAR_OPTIONS}
                  onChange={yearSelectHandler}
                  error={propsForField('year').error}
                  width="150px"
                  disabled={readOnly}
                />
              </Grid>
              <Grid
                item
                style={{ paddingLeft: PADDING, paddingRight: PADDING }}
              >
                <StyledAutocompleteInput
                  label="Quarter"
                  width="150px"
                  value={`${values.quarter}`}
                  onChange={quarterSelectHandler}
                  options={[
                    NONE,
                    { value: '1', label: 'Q1' },
                    { value: '2', label: 'Q2' },
                    { value: '3', label: 'Q3' },
                    { value: '4', label: 'Q4' },
                  ]}
                  error={propsForField('quarter').error}
                  disabled={readOnly}
                />
              </Grid>
              <Grid
                item
                style={{ paddingLeft: PADDING, paddingRight: PADDING }}
              >
                <DateSelect
                  label="Start Date"
                  value={values.createdDate}
                  onChange={startDateSelectHandler}
                  error={propsForField('startDate').error}
                />
              </Grid>
              <Grid
                item
                style={{ paddingLeft: PADDING, paddingRight: PADDING }}
              >
                <DateSelect
                  label="Completed Date"
                  value={values.completedDate}
                  onChange={completedDateSelectHandler}
                  error={propsForField('completedDate').error}
                />
              </Grid>
              <Grid
                item
                style={{ paddingLeft: PADDING, paddingRight: PADDING, width: '18em' }}
              >
                <StyledAutocompleteInput
                  label="Person"
                  value={values.person}
                  options={readOnly ? [{ value: walkdown.person, label: walkdown.personName }] : userOptions}
                  onChange={personSelectHandler}
                  error={propsForField('person').error}
                  disabled={readOnly}
                />
              </Grid>
              <Grid
                container
                style={{ paddingTop: '4em' }}
              >
                <UploadAttachmentsPage walkdownId={walkdownId} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div style={{ padding: PADDING }}>
          {walkdown.categories.map((c: WalkdownCategory) => (
            <WalkdownCategoryCard
              walkdownId={walkdownId}
              category={c}
              key={c.id}
            />
          ))}
        </div>
      </ReadOnlyProvider>
    </div>
  );
};

export default SingleWalkdownInternal;
