import { ElementId } from '../../types/app';
import isFeatureEnabled from '../../utils/feature-flags';
import { Path, Remote } from '../types';
import { IndicatorRecord } from './types';

const getReportIndicators = (path: Path, remote: Remote) => (type: string) => remote.get(path(`/${type}`));
const getAllIndicators = (path: Path, remote: Remote) => () => remote.get(path('/'));
const getIndicator = (path: Path, remote: Remote) => (indicatorId: number) => remote.get(path(`/${indicatorId}`));
const getIndicatorsByShortName = (path: Path, remote: Remote) => (shortNames: Array<string>) =>
  remote.get(path('/'), { params: { shortNames } });
const getManualIndicators = (path: Path, remote: Remote) => () => remote.get(path('/'), { params: { manual: true } });
const createIndicator = (path: Path, remote: Remote) => (elementType: string, name: string) =>
  remote.post(path('/'), { indicator: { name, elementType } });
const getProgramIndicator = (path: Path, remote: Remote) => (indicatorId: number) =>
  remote.get(path(`/program/${indicatorId}`));
const getProgramElementIndicator = (path: Path, remote: Remote) => (indicatorId: number, elementId: number) =>
  remote.get(path(`/program/${indicatorId}/element/${elementId}`));
const getIndicatorRecords =
  (path: Path, remote: Remote) =>
  (
    elementId: ElementId,
    indicatorId: number,
    recordId: string,
    year?: number,
    quarter?: string
  ): Promise<IndicatorRecord[]> => {
    const params = {
      elementId,
      recordId,
      ...(isFeatureEnabled('liveScorecardOverrides') ? { year, quarter } : {}),
    };
    return remote.get(path(`/${indicatorId}/records`), { params });
  };

export default (path: Path, remote: Remote) => ({
  createIndicator: createIndicator(path, remote),
  getReportIndicators: getReportIndicators(path, remote),
  getAllIndicators: getAllIndicators(path, remote),
  getIndicator: getIndicator(path, remote),
  getProgramIndicator: getProgramIndicator(path, remote),
  getProgramElementIndicator: getProgramElementIndicator(path, remote),
  getIndicatorsByShortName: getIndicatorsByShortName(path, remote),
  getManualIndicators: getManualIndicators(path, remote),
  getIndicatorRecords: getIndicatorRecords(path, remote),
});
