import { ControlRadio } from '../../../../form/ControlRadio';
import { ControlTextField } from '../../../../form/ControlTextField';
import Grid from '@material-ui/core/Grid';
import { ControlAutocomplete } from '../../../../form/ControlAutocomplete';
import useUsers from '../../../../hooks/users';
import { User } from '../../../../types/user';
import { DecisionOptions } from '../hooks/useRiskApproverForm';
import { Control, FieldErrors, FieldError } from 'react-hook-form';
import { RiskApproverFormInputs } from '../../../../types/risk-management';
import { UppercaseTitle } from './UppercaseTitle';

export type LegacyApproverInfoProps = {
  control: Control<RiskApproverFormInputs>;
  errors: FieldErrors<RiskApproverFormInputs>;
  decisionOptions: DecisionOptions;
  yesOrNoOptions: { value: string; label: string }[];
  watchFlagToCno: string | null;
  watchEscalateIssue: string | null;
  isReadOnly: boolean;
  isDecisionDescRequired: boolean;
};

export const LegacyApproverInfo = (props: LegacyApproverInfoProps) => {
  const { users }: { users: User[] } = useUsers();
  const {
    control,
    errors,
    decisionOptions,
    yesOrNoOptions,
    watchFlagToCno,
    watchEscalateIssue,
    isReadOnly,
    isDecisionDescRequired,
  } = props;

  const cnoFields: {
    name: keyof RiskApproverFormInputs;
    label: string;
    errors?: FieldError;
  }[] = [
    { name: 'cnoDescription', label: 'CNO Short Risk Description', errors: errors.cnoDescription },
    { name: 'cnoBridgingStrategy', label: 'CNO Short Risk Bridging Strategy', errors: errors.cnoBridgingStrategy },
    { name: 'cnoStatusUpdate', label: 'CNO Short Risk Status Update', errors: errors.cnoStatusUpdate },
    { name: 'riskResponseSummary', label: 'CNO Risk Response Summary', errors: errors.riskResponseSummary },
  ];

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <UppercaseTitle>General Information</UppercaseTitle>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ControlRadio
          control={control}
          error={!!errors.escalateIssue}
          name="escalateIssue"
          label="Want to escalate issue?"
          options={yesOrNoOptions}
          readOnly={isReadOnly}
          row
        />
      </Grid>
      {watchEscalateIssue === 'yes' && (
        <Grid
          item
          xs={12}
        >
          <ControlAutocomplete
            control={control}
            name="escalateUsers"
            options={users.map((user: User) => ({ label: user.name, value: user.id }))}
            error={!!errors.escalateUsers}
            label="Who do you want to escalate to?"
            required={watchEscalateIssue === 'yes'}
            readOnly={isReadOnly}
            multiple={true}
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
      >
        <ControlRadio
          control={control}
          required
          label="Decision Type"
          name="decisionType"
          options={decisionOptions}
          error={!!errors.decisionType}
          readOnly={isReadOnly}
          row
        />
      </Grid>
      {isDecisionDescRequired && (
        <Grid
          item
          xs={12}
        >
          <ControlTextField
            control={control}
            required
            name="decisionTypeDescription"
            error={!!errors.decisionTypeDescription}
            label="Decision Type Description"
            disabled={isReadOnly}
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
      >
        <ControlRadio
          control={control}
          label="Flag to CNO"
          name="flagToCno"
          options={yesOrNoOptions}
          error={!!errors.flagToCno}
          readOnly={isReadOnly}
          row
        />
      </Grid>
      {watchFlagToCno === 'yes' && (
        <>
          {cnoFields.map(field => (
            <Grid
              key={field.name}
              item
              xs={12}
            >
              <ControlTextField
                control={control}
                required
                name={field.name}
                error={!!field.errors}
                maxLength={3000}
                multiline={true}
                minRows={6}
                label={field.label}
                readOnly={isReadOnly}
                placeholder="Required (Character Limitation: 3000 characters)"
              />
            </Grid>
          ))}
        </>
      )}
    </>
  );
};
