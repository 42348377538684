import React from 'react';
import styled from 'styled-components';
import MuiTypography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import UnstyledTotalScoreBox from './Scorecard/TotalScoreBox';
import usePreviousScore from '../hooks/previous-score';
import LoadingContainer from './LoadingContainer';

const Typography = styled(MuiTypography)`
  font: ${({ theme }) => theme.fonts.normal14};
  align: center;
`;

const TotalScoreBox = styled(UnstyledTotalScoreBox)`
  height: 33px;
  width: 107px;
  margin-left: 22px;
`;

const PreviousStoryDialog = ({ indicatorName }: any) => {
  const { previousScoreData, loading } = usePreviousScore();

  return (
    <LoadingContainer loading={loading}>
      {previousScoreData ? (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>
            Report {previousScoreData.year} - Q{previousScoreData.quarter}
          </Typography>

          <Typography>{indicatorName}</Typography>

          <TotalScoreBox
            totalScore={previousScoreData.score}
            scoreColour={previousScoreData.colour}
          />
        </Box>
      ) : (
        <p>
          <em>No previous records exist</em>
        </p>
      )}
    </LoadingContainer>
  );
};

export default PreviousStoryDialog;
