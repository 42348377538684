import { Table, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import ActionButton from '../ActionButton';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import Api from '../../api';
import { LabelOptions, Risk, USER_ROLES } from '../../types/risk-management';
import { format, getQuarter } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { getDecisionOption } from '../../pages/business-equip-risks/components/hooks/utils';
import { FilterOptions } from '../../pages/business-equip-risks/components/FilterOptions';
import isFeatureEnabled from '../../utils/feature-flags';
import { MyElement } from '../../types/my';

export interface RiskFilterOptions {
  decisionType: string | null;
  quarter: string | null;
  workflowStatus: string | null;
  riskId: number | null;
  riskTitle: string | null;
  workflowStatusDate: string | null;
  year: number | null;
  riskOwner: string | null;
  envTeamContact: string | null;
  assignedApprover: string | null;
  lastRiskApprover: string | null;
  riskEscalationName: string | null;
}

interface RowProps {
  risk: Risk;
  state: string;
  userRoles: USER_ROLES[];
  riskApprovers: LabelOptions[];
  rowIndex: number;
}

interface Props {
  elementId?: string;
  setElementId: Dispatch<SetStateAction<string>>;
  state: string;
  currentRiskManagementUserRoles: USER_ROLES[];
  riskApprovers: LabelOptions[];
  myElements: MyElement[];
}

const DATE_FORMAT = 'dd/MM/yyyy';

interface ActiveButtonsProps {
  risk: Risk;
}

const isEditDisabled = (risk: Risk) => {
  if (isFeatureEnabled('riskOwnersGroup') && risk.isRSE && !risk.isRiskOwner) {
    return true;
  } else {
    return false;
  }
};

const lastDecisionInfo = (risk: Risk) => {
  if (risk.status === 'DRAFT') {
    return { approver: risk.prevApproverName || 'N/A', decision: getDecisionOption(risk.prevDecisionType || 'none') };
  } else if (risk.status === 'EDITED') {
    return { approver: risk.riskOwnerUpdatedByName || 'N/A', decision: getDecisionOption('editByApprover') };
  }
  return { approver: risk.riskApproverName || 'N/A', decision: getDecisionOption(risk.decisionType || 'none') };
};

const riskPendingMessage = (status: string, awaitingEscalation: boolean): string => {
  if (status === 'SUBMITTED' && awaitingEscalation) {
    return 'Pending for Escalation';
  } else if (status === 'ENV_REQUIRED') {
    return 'Pending for Enviro Group';
  }

  return '';
};

const ActiveButtons = ({ risk }: ActiveButtonsProps) => {
  const { push } = useHistory();

  return (
    <>
      {risk.status === 'DRAFT' && (
        <TableCell>
          <ActionButton
            variant="text"
            onClick={() => {
              push(`/app/business-equipment-risks/${risk.riskManagementId}/edit`);
            }}
            disabled={isEditDisabled(risk)}
          >
            Edit
          </ActionButton>
        </TableCell>
      )}
    </>
  );
};

const ArchiveButtons = ({ risk }: { risk: Risk }) => {
  const { push } = useHistory();
  return (
    <TableCell>
      <ActionButton
        variant="text"
        onClick={() => {
          push(`/app/business-equipment-risks/${risk.riskManagementId}/archive`);
        }}
      >
        View
      </ActionButton>
    </TableCell>
  );
};

type ApprovalButtonsProps = { risk: Risk; userRoles: USER_ROLES[] };

const ApprovalButtons = ({ risk, userRoles }: ApprovalButtonsProps) => {
  const { push } = useHistory();

  const isReviewButtonDisabled = useMemo(() => {
    return (
      (!userRoles.includes(USER_ROLES.APPROVER) && risk.status === 'SUBMITTED') ||
      (!userRoles.includes(USER_ROLES.ENVIRONMENT_GROUP) && risk.status === 'ENV_REQUIRED')
    );
  }, [risk, userRoles]);

  return (
    <>
      <TableCell>{null}</TableCell>
      <TableCell>
        <ActionButton
          disabled={isReviewButtonDisabled}
          variant="text"
          onClick={() => {
            push(`/app/business-equipment-risks/${risk.riskManagementId}/review`);
          }}
        >
          Review
        </ActionButton>
      </TableCell>
    </>
  );
};

const RowActionButtons = ({ state, risk, userRoles }: Omit<RowProps, 'riskApprovers' | 'rowIndex'>) => {
  switch (state) {
    case 'active':
      return <ActiveButtons risk={risk} />;
    case 'archive':
      return <ArchiveButtons risk={risk} />;
    case 'approval':
      return (
        <ApprovalButtons
          risk={risk}
          userRoles={userRoles}
        />
      );
    default:
      return null;
  }
};

const HeaderActionButtons = ({ state }: { state: string }) => {
  switch (state) {
    case 'active':
    case 'approval':
      return (
        <>
          <TableCell>{null}</TableCell>
          <TableCell>{null}</TableCell>
        </>
      );
    case 'archive':
      return <TableCell>{null}</TableCell>;
    default:
      return null;
  }
};

const Row = ({ risk, state, userRoles, riskApprovers, rowIndex }: RowProps) => {
  const isApprovalPage = state === 'approval';
  const doRenderApprovers = isApprovalPage && risk.requestedRiskApprovers.length > 0;
  // if there is actually more than one we should sort it to put the selected first
  const riskApproverNames = doRenderApprovers
    ? risk.requestedRiskApprovers
        .map(userId => riskApprovers.find(({ value }) => value === userId)?.label)
        .filter(approverName => !!approverName)
        .join(', ')
    : '';

  return (
    <TableRow style={{ width: '100%', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
      <TableCell>{risk.originalRiskManagementForm || risk.riskManagementId}</TableCell>
      <TableCell>{risk.riskTitle}</TableCell>
      <TableCell align="center">{lastDecisionInfo(risk).decision}</TableCell>
      <TableCell align="center">Q{getQuarter(new Date(risk.statusDate))}</TableCell>
      <TableCell align="center">{format(new Date(risk.statusDate), DATE_FORMAT)}</TableCell>
      <TableCell align="center">{risk.status}</TableCell>
      {state !== 'approval' && <TableCell align="center">{lastDecisionInfo(risk).approver}</TableCell>}
      {isApprovalPage && (
        <TableCell
          data-testid={`risk-approver-${rowIndex}`}
          align="center"
        >
          {riskApproverNames}
        </TableCell>
      )}
      <TableCell align="center">{riskPendingMessage(risk.status, risk.awaitingEscalation)}</TableCell>
      <RowActionButtons
        state={state}
        risk={risk}
        userRoles={userRoles}
      />
    </TableRow>
  );
};

const STATE_MAP: { [x: string]: string[] } = {
  active: ['submitted', 'env_required', 'draft'],
  archive: ['completed', 'cancelled', 'rejected', 'approved', 'closed', 'edited'],
  approval: ['submitted', 'env_required'],
};

const tableFilter = (filterOptions: RiskFilterOptions) => (risk: Risk) =>
  (!filterOptions.riskId || filterOptions.riskId === risk.originalRiskManagementForm) &&
  (!filterOptions.workflowStatus || risk.status === filterOptions.workflowStatus) &&
  (!filterOptions.riskTitle || risk.riskTitle === filterOptions.riskTitle) &&
  (!filterOptions.workflowStatusDate ||
    format(new Date(risk.statusDate), DATE_FORMAT) === filterOptions.workflowStatusDate) &&
  (!filterOptions.quarter || getQuarter(new Date(risk.statusDate)) === Number(filterOptions.quarter)) &&
  (!filterOptions.decisionType ||
    risk.decisionType === filterOptions.decisionType ||
    risk.prevDecisionType === filterOptions.decisionType) &&
  (!filterOptions.year || new Date(risk.statusDate).getFullYear() === filterOptions.year) &&
  (!filterOptions.riskOwner || risk.riskOwnerName === filterOptions.riskOwner) &&
  (!filterOptions.envTeamContact || risk.envContactName === filterOptions.envTeamContact) &&
  (!filterOptions.lastRiskApprover || lastDecisionInfo(risk).approver === filterOptions.lastRiskApprover) &&
  (!filterOptions.assignedApprover || (risk.requestedRiskApprovers || []).includes(filterOptions.assignedApprover)) &&
  (!filterOptions.riskEscalationName || risk.awaitingEscalationToName === filterOptions.riskEscalationName);

export const RiskTable = ({
  elementId,
  state,
  currentRiskManagementUserRoles,
  setElementId,
  riskApprovers,
  myElements,
}: Props) => {
  const [risks, setRisks] = useState<Risk[]>([]);

  const [filterOptions, setFilterOptions] = useState<RiskFilterOptions>({
    decisionType: null,
    quarter: null,
    workflowStatus: null,
    riskId: null,
    riskTitle: null,
    workflowStatusDate: null,
    year: null,
    riskOwner: null,
    envTeamContact: null,
    assignedApprover: null,
    lastRiskApprover: null,
    riskEscalationName: null,
  });

  const status = useMemo<string[]>(() => STATE_MAP[state], [state]);

  const fetchRisks = useCallback(async () => {
    const response = await Api.getMyRiskManagementRisks(elementId || '', status);
    setRisks(response.risks);
  }, [status, elementId]);

  useEffect(() => {
    void fetchRisks();
  }, [elementId, status]);

  const Rows = useCallback(
    (props: { children?: React.ReactNode }) =>
      risks.length > 0 ? (
        <>
          {risks.filter(tableFilter(filterOptions)).map((risk, i) => (
            <Row
              key={risk.riskManagementId + risk.statusDate}
              rowIndex={i}
              risk={risk}
              state={state}
              userRoles={currentRiskManagementUserRoles}
              riskApprovers={riskApprovers}
            />
          ))}
          {props.children}
        </>
      ) : null,
    [risks, elementId, currentRiskManagementUserRoles, filterOptions, riskApprovers, state]
  );

  const riskIds: number[] = useMemo(() => {
    const ids = risks.map(risk => risk.originalRiskManagementForm);
    return ids
      .filter((riskId, index) => riskId && ids.indexOf(riskId) === index) // remove duplicates
      .sort((a, b) => a - b);
  }, [risks]);

  const riskTitles: string[] = useMemo(() => {
    const titles = risks.map(risk => risk.riskTitle);
    return titles
      .filter((riskTitle, index) => riskTitle && titles.indexOf(riskTitle) === index)
      .sort((a, b) => a.localeCompare(b)); // remove duplicates
  }, [risks]);

  const riskOwners: string[] = useMemo(() => {
    const owners = risks.map(risk => risk.riskOwnerName);
    return owners.filter((riskOwnerName, index) => riskOwnerName && owners.indexOf(riskOwnerName) === index).sort();
  }, [risks]);

  const envTeamContacts: string[] = useMemo(() => {
    const contacts = risks.map(risk => risk.envContactName);
    return contacts
      .filter((envContactName, index) => envContactName && contacts.indexOf(envContactName) === index)
      .sort((a, b) => a.localeCompare(b));
  }, [risks]);

  const lastRiskApprovers: string[] = useMemo(() => {
    const approvers = risks.map(risk => lastDecisionInfo(risk).approver);

    return state !== 'approval'
      ? approvers
          .filter((approverName, index) => approverName && approvers.indexOf(approverName) === index)
          .sort((a, b) => a.localeCompare(b))
          .filter(approverName => approverName !== 'N/A')
      : [];
  }, [risks, state]);

  const riskEscalationNames: string[] = useMemo(() => {
    const escalations = risks.filter(risk => risk.awaitingEscalation).map(risk => risk.awaitingEscalationToName);

    return escalations
      .filter((escalateToName, index) => escalateToName && escalations.indexOf(escalateToName) === index)
      .sort((a, b) => a.localeCompare(b));
  }, [risks]);

  const statusDates: string[] = useMemo(() => {
    const statuses = risks.map(risk => format(new Date(risk.statusDate), DATE_FORMAT));
    return statuses.filter((status, index) => status && statuses.indexOf(status) === index);
  }, [risks]);

  const years: number[] = useMemo(() => {
    const years = risks.map(risk => new Date(risk.statusDate).getFullYear());
    return years.filter((year, index) => year && years.indexOf(year) === index);
  }, [risks]);

  return (
    <>
      <FilterOptions
        onChangeFilterOptions={(key, value) => {
          setFilterOptions({
            ...filterOptions,
            [key]: value,
          });
        }}
        riskIds={riskIds}
        riskTitles={riskTitles}
        workflowStatusDates={statusDates}
        elementId={elementId}
        setElementId={setElementId}
        years={years}
        riskOwners={riskOwners}
        envTeamContacts={envTeamContacts}
        lastRiskApprovers={lastRiskApprovers}
        riskEscalationNames={riskEscalationNames}
        state={state}
        riskApprovers={riskApprovers}
        currentRiskManagementUserRoles={currentRiskManagementUserRoles}
        myElements={myElements}
      />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Risk ID</TableCell>
              <TableCell>Risk Title</TableCell>
              <TableCell align="center">Decision Type</TableCell>
              <TableCell align="center">Quarter</TableCell>
              <TableCell align="center">Workflow Status Date</TableCell>
              <TableCell align="center">Workflow Status</TableCell>
              {state !== 'approval' && <TableCell align="center">Risk Approver</TableCell>}
              {state === 'approval' && <TableCell align="center">Assigned Approver</TableCell>}
              <TableCell></TableCell>
              <TableCell></TableCell>
              <HeaderActionButtons state={state} />
            </TableRow>
          </TableHead>
          <Rows />
        </Table>
      </TableContainer>
    </>
  );
};
