import styled from 'styled-components';
import Box, { BoxProps } from '@material-ui/core/Box';
import { dateTimeToString } from '../../../utils/date-format';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';

const YellowBell = styled(NotificationImportantIcon)`
  height: 20px;
  width: auto;
  color: #ffa22d;
`;

interface LastUpdatedWarningProps extends BoxProps {
  updatedBy?: string;
  updatedAt?: string;
  marginBottom?: string;
}

const ColourBox = styled(Box)<BoxProps>`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px;

  border-radius: 4px;

  background-color: #fff4e5;
  color: #663c00;

  font: 400 16px;
`;

const LastUpdatedWarning = (props: LastUpdatedWarningProps) => {
  const { updatedBy, updatedAt, marginBottom } = props;
  const updatedAtFormatted = updatedAt ? dateTimeToString(new Date(updatedAt)) : updatedAt;

  return (
    <ColourBox marginBottom={marginBottom}>
      <YellowBell />
      Last modified: {updatedBy} on {updatedAtFormatted}
    </ColourBox>
  );
};

export default LastUpdatedWarning;
