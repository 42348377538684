import styled from 'styled-components';
import * as R from 'ramda';
import UnstyledPanel from '../../../../../components/Panel';
import UnstyledFilterSelector from '../../../../../components/FilterSelector';
import useElementUnits from '../../../../../hooks/element-units';
import useElementIndicators, { ElementIndicatorsProvider } from '../../../../../hooks/element-indicators';
import { type ElementIndicator } from '../../../../../types/element-indicators';
import AutocompleteInput from '../../../../../components/AutocompleteInput';
import { Grid } from '@material-ui/core';
import { ElementUnit, FilterPanelType, IndicatorSelectorType } from '../../../../../types/record-details';
import { isBlackListedIndicator } from '../../../../../components/Scorecard/utils';

const FilterSelector = styled(UnstyledFilterSelector)``;

const Panel = styled(UnstyledPanel)`
  flex-wrap: wrap;
  margin-bottom: 14px;

  ${FilterSelector} {
    margin-right: 10px;
  }
`;

const IndicatorSelector = ({ indicatorId, onChangeIndicator }: IndicatorSelectorType) => {
  const { elementIndicators } = useElementIndicators();
  const calculatedIndicators = R.filter(R.propEq('indicatorType', 'calculated'), elementIndicators);
  const indicatorOptions: { label: string; value: string }[] = calculatedIndicators
    .filter(i => !isBlackListedIndicator(i.indicatorShortName))
    .map((i: ElementIndicator) => ({
      label: i.indicatorName,
      value: `${i.indicatorId}`,
    }));

  return (
    <AutocompleteInput
      label="Indicator"
      options={indicatorOptions}
      value={indicatorId}
      onChange={onChangeIndicator}
    />
  );
};

const FilterPanel = ({ className, elementId, unit, indicatorId, onChangeUnit, onChangeIndicator }: FilterPanelType) => {
  const { elementUnits }: { elementUnits: ElementUnit[] } = useElementUnits(elementId);

  const unitOptions = elementUnits.map(elementUnit => ({
    label: elementUnit.elementUnit,
    value: elementUnit.elementUnit,
  }));

  return (
    <Panel className={className}>
      <Grid
        container
        spacing={2}
        item
        xs={12}
      >
        {elementId && (
          <>
            <Grid
              item
              md={4}
              sm={6}
              xs={12}
            >
              <ElementIndicatorsProvider elementId={elementId}>
                <IndicatorSelector
                  indicatorId={indicatorId}
                  onChangeIndicator={onChangeIndicator}
                />
              </ElementIndicatorsProvider>
            </Grid>
            <Grid
              item
              md={4}
              sm={6}
              xs={12}
            >
              <AutocompleteInput
                label="All Units"
                options={unitOptions}
                value={unit}
                onChange={value => onChangeUnit(value || '')}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Panel>
  );
};

export default FilterPanel;
