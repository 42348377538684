import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import CarlaText from '../../../../../../components/CarlaText';
import DialogButton from '../../../../../../components/DialogButton';
import CustomTextField from '../../../../../../components/CustomTextField';
import Select from '../../../../../../components/Select';
import { useCallback, useState } from 'react';
import * as uiActions from '../../../../../../state/ui/actions';
import { useDispatch } from 'react-redux';
import { useBusinessObjectivesContext } from '../../../../../../context/business-objective';
import LoadingContainer from '../../../../../../components/LoadingContainer';

type Props = {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const NewBusinessObjectiveDashboard = ({ opened, setOpened }: Props) => {
  const { addBusinessObjective, isLoading } = useBusinessObjectivesContext();
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState('Active');
  const dispatch = useDispatch();

  const handleAdd = useCallback(async () => {
    if (title && status) {
      try {
        await addBusinessObjective(title, status);
        dispatch(uiActions.genericMessage('Added Successfully'));
        setOpened(false);
      } catch (err: any) {
        dispatch(uiActions.error('', err?.message ?? err ?? 'Failed to add Business Objective'));
      }
    } else {
      dispatch(uiActions.error('', 'Please enter valid input'));
    }
  }, [title, status]);

  return (
    <>
      <Dialog
        open={opened}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Add new Business Objective</DialogTitle>
        <DialogContent>
          {isLoading && <LoadingContainer loading />}
          {!isLoading && (
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <CarlaText>Enter title and set status</CarlaText>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <CustomTextField
                  label="Title"
                  inputProps={{ maxLength: 256 }}
                  onChange={e => setTitle(e.target.value)}
                ></CustomTextField>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <Select
                  fullWidth
                  label="Status"
                  options={[
                    { label: 'Active', value: 'Active' },
                    { label: 'In-Active', value: 'In-Active' },
                  ]}
                  onChange={e => setStatus(e.target.value)}
                  value={status}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        {!isLoading && (
          <DialogActions>
            <DialogButton onClick={() => setOpened(false)}>Cancel</DialogButton>
            <DialogButton
              color="primary"
              onClick={handleAdd}
            >
              Submit
            </DialogButton>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default NewBusinessObjectiveDashboard;
